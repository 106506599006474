export const routesLegal = [
    {
        name: 'LegalEdit',
        path: '/legal/edit',
        component: () => import('pages/Legal/Edit/Index.vue'),
    },
    {
        name: 'LegalStocks',
        path: '/legal/stocks',
        component: () => import('pages/Legal/Stocks/Index.vue'),
        meta: {
            help: '/doc/punkty-vydachi-yygqdilyOK',
        },
    },
    {
        name: 'LegalStocksCreate',
        path: '/legal/stocks/create',
        component: () => import('pages/Legal/Stocks/Form.vue'),
        meta: {
            help: '/doc/punkty-vydachi-yygqdilyOK#h-sozdanie-pvz',
        },
    },
    {
        name: 'LegalStocksEdit',
        path: '/legal/stocks/:id',
        props: true,
        component: () => import('pages/Legal/Stocks/Form.vue'),
        meta: {
            help: '/doc/punkty-vydachi-yygqdilyOK#h-redaktirovanie-pvz',
        },
    },
    {
        name: 'DayoffCalendar',
        path: '/legal/calendar',
        props: true,
        component: () => import('pages/Legal/Stocks/Calendar.vue'),
        meta: {
            help: '/doc/kalendar-nerabochih-dnej-evyHrxQ6Tz',
        },
    },
    {
        name: 'LegalShippers',
        path: '/legal/shippers',
        component: () => import('pages/Legal/Shippers/Index.vue'),
    },
    {
        name: 'LegalShippersEdit',
        path: '/legal/shippers/:id',
        props: true,
        component: () => import('pages/Legal/Shippers/Form.vue'),
    },
];
