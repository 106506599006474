import { createEffect, createEvent, restore, guard } from 'effector';
import { StocksApi } from 'api/StocksApi';
import { findById, arrayToObject } from 'utils/array';

export const getRegions = createEffect((params) => StocksApi.getRegions(params));
export const addGeography = createEffect((model) => StocksApi.addRegion(model));

export const $regions = restore(getRegions, [])
    .on(addGeography.done, () => getRegions());

export const getLegalStocks = createEvent();

export const getUserStocks = createEffect(() => StocksApi.onUser());
export const legalStocksLoader = createEffect(() => StocksApi.onLegal());
export const getServices = createEffect(() => StocksApi.getServices());

export const $userStocks = restore(getUserStocks, []);
export const $legalStocks = restore(legalStocksLoader, []);

export const $services = restore(getServices, []);

export const $activeStocks = $userStocks.map((stocks) => stocks.filter((stock) => !stock.deleted_at));

export const $userStocksOptions = $activeStocks.map((stocks) =>
    stocks.map((stock) => ({ label: stock.name, value: stock.id }))
);
export const $legalStocksOptions = $legalStocks.map((stocks) =>
    stocks.map((stock) => ({ label: stock.name, value: stock.id }))
);

guard({
    clock: getLegalStocks,
    source: $legalStocks,
    filter: stocks => stocks.length === 0,
    target: legalStocksLoader,
});

export const $stocksObject = $userStocks.map((stocks) => arrayToObject(stocks));
export const $legalStocksObject = $legalStocks.map((stocks) => arrayToObject(stocks));

export const getStocksByLocation = createEffect((params) => StocksApi.getStocksByLocation(params));
export const $stocksByLocation = restore(getStocksByLocation, []);

export const getStockCode = (id) => {
    const stock = findById($legalStocks.getState(), id);

    return stock ? stock.name : '';
};
