export const routesReport = [
    {
        name: 'ReportDelivery',
        path: '/report/delivery',
        component: () => import('pages/Reports/Delivery/Index.vue'),
        meta: {
            help: '/doc/otchyot-po-zakazam-VTTJIIxkoL',
        },
    },
    {
        name: 'ReportPeriod',
        path: '/report/period',
        component: () => import('pages/Reports/Period/Index.vue'),
    },
    {
        name: 'ReportPvz',
        path: '/report/Pvz',
        component: () => import('pages/Reports/Pvz/Index.vue'),
    },
    {
        name: 'ReportOperator',
        path: '/report/operator',
        component: () => import('pages/Reports/Operator/Index.vue'),
    },
    {
        name: 'ReportOrdersPvz',
        path: '/report/orders_pvz',
        component: () => import('pages/Reports/OrdersPvz/Index.vue'),
    },
    {
        name: 'ReportAgentList',
        path: '/report/agent',
        component: () => import('pages/Reports/Agent/Index.vue'),
    },
    {
        name: 'ReportAgent',
        path: '/report/agent/:id',
        props: true,
        component: () => import('pages/Reports/Agent/Report.vue'),
    },
    {
        name: 'ReportBalance',
        path: '/report/balance',
        component: () => import('pages/Reports/Balance/Index.vue'),
        meta: {
            help: '/doc/balans-E8ZMZoofIa',
        },
    },
];
