var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-file", {
    attrs: {
      outlined: _vm.outlined,
      "max-file-size": _vm.maxFileSize,
      dense: _vm.dense,
      denser: _vm.denser,
      disable: _vm.disable,
      accept: _vm.accept
    },
    on: { input: val => _vm.$emit("input", val), rejected: _vm.onRejected },
    scopedSlots: _vm._u(
      [
        {
          key: "prepend",
          fn: function() {
            return [_c("q-icon", { attrs: { name: "attach_file" } })]
          },
          proxy: true
        },
        !!_vm.label
          ? {
              key: "label",
              fn: function() {
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.label) + " "),
                    _vm.required
                      ? _c("b", { staticClass: "text-negative" }, [_vm._v("*")])
                      : _vm._e()
                  ])
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "hint",
          fn: function() {
            return [_vm._v("\n        " + _vm._s(_vm.hint) + "\n    ")]
          },
          proxy: true
        }
      ],
      null,
      true
    ),
    model: {
      value: _vm.file,
      callback: function($$v) {
        _vm.file = $$v
      },
      expression: "file"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }