var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-uploader", {
    attrs: {
      flat: "",
      "hide-upload-btn": "",
      accept: _vm.accept,
      multiple: _vm.multiple,
      "max-files": _vm.maxFiles,
      "max-file-size": _vm.maxFileSize
    },
    on: { added: val => _vm.$emit("input", val) },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function(scope) {
          return [
            _c(
              "div",
              { staticClass: "row no-wrap items-center q-pa-sm q-gutter-xs" },
              [
                scope.queuedFiles.length > 0
                  ? _c(
                      "q-btn",
                      {
                        attrs: {
                          icon: "delete_forever",
                          round: "",
                          dense: "",
                          flat: ""
                        },
                        on: { click: scope.removeQueuedFiles }
                      },
                      [_c("q-tooltip", [_vm._v("Удалить все")])],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col" }, [
                  !!_vm.label
                    ? _c("div", { staticClass: "q-uploader__title" }, [
                        _vm._v(_vm._s(_vm.label))
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "q-uploader__subtitle" }, [
                    _vm._v(
                      _vm._s(scope.files.length) +
                        " файл(ов) / " +
                        _vm._s(scope.uploadSizeLabel)
                    )
                  ])
                ]),
                scope.canAddFiles
                  ? _c(
                      "q-btn",
                      {
                        attrs: {
                          type: "a",
                          icon: "add_box",
                          round: "",
                          dense: "",
                          flat: ""
                        },
                        on: { click: scope.pickFiles }
                      },
                      [
                        _c("q-uploader-add-trigger"),
                        _c("q-tooltip", [_vm._v("Добавить файл(ы)")])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }