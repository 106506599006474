<template>
    <q-list class="user-toolbar">
        <q-item dark>
            <q-item-section avatar>
                <logo-svg
                    height="42px"
                    width="52px"
                />
            </q-item-section>
            <q-item-section>
                <q-item-label>{{ user.login }}</q-item-label>
                <q-item-label caption>{{ user.name }}</q-item-label>
            </q-item-section>
            <q-item-section side>
                <q-btn dense round flat color="white" title="Выйти" icon="exit_to_app" @click="authLogout"/>
            </q-item-section>
        </q-item>
    </q-list>
</template>

<script>
import { $authUser, authLogout } from 'store/auth';
import LogoSVG from './LogoSVG.vue';

export default {

    components: {
        'logo-svg': LogoSVG,
    },

    effector: { user: $authUser},

    computed: {
        stockId() {
            return this.stock.id && parseInt(this.stock.id);
        },
    },

    methods: {
        authLogout,
    },
};
</script>

<style lang="scss" scoped>
    .user-toolbar {
        background: $dark;
    }
</style>
