import { connection, handleRequest, KktApiInstance } from './KktApiInstance';

class Operations extends KktApiInstance {
    constructor(url) {
        super(url);
    }

    queryDeviceStatus(params, id = null) {
        return handleRequest(connection.post(`${this.baseUrl}/queryDeviceStatus`, params, { id }));
    }
}

export const OperationsApi = new Operations('/operations');
