import { date } from 'quasar';

export const getRubleFormat = (count) => `${count} руб.`;
export const getKiloFormat = (count) => `${count} кг.`;
export const getDateFormat = (val) => date.formatDate(val, 'DD.MM.YY HH:mm');

export const currencyFormatter = (value, currency = 'RUB') =>
    new Intl.NumberFormat('ru', { style: 'currency', currency }).format(value);

export const encodeHTMLToBase64 = (str) => {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(_, p1) {
        return String.fromCharCode('0x' + p1);
    }));
};
