export const routesAdmin = [
    /**
     * Пользователи
     */
    {
        name: 'Users',
        path: '/users',
        component: () => import('pages/Admin/Users/Index.vue'),
        meta: {
            help: '/doc/polzovateli-Z888Opu73a',
        },
    },
    {
        name: 'UsersCreate',
        path: '/users/create',
        component: () => import('pages/Admin/Users/Form.vue'),
        meta: {
            help: '/doc/polzovateli-Z888Opu73a#h-sozdanie-polьzovatelya',
        },
    },
    {
        name: 'UsersEdit',
        path: '/users/:id',
        props: true,
        component: () => import('pages/Admin/Users/Form.vue'),
        meta: {
            help: '/doc/polzovateli-Z888Opu73a#h-redaktirovanie-polьzovatelya',
        },
    },
];
