import { DeliveryApi } from 'api/DeliveryApi';
import { $ordersToReceipt, refreshOrdersToReceiptFromApi } from './orders';
import { createCoeffect } from 'utils/effector';
import { refreshPickupOrdersFromApi } from 'store/delivery';
import { orderState } from 'config/';

const $ordersAccepted = $ordersToReceipt.map(
    (orders) => orders
        .filter((order) => order.state === orderState.IN_STORE)
        .map(({ id, cell_id = null }) => ({ id, cell_id }))
);
export const $acceptButtonDisabled = $ordersAccepted.map((orders) => orders.length === 0);

export const submitOrdersReception = createCoeffect($ordersAccepted, (orders) => DeliveryApi.receiptOrders(orders));

submitOrdersReception.done.watch(({ params }) => {
    refreshPickupOrdersFromApi();
    refreshOrdersToReceiptFromApi(params);
});
