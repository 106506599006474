export const orderService = {
    'C24KO': 1, //Выдача с оплатой
    'C24': 11, //Выдача
    'ZABOR': 22, //Забор
    // '24KO': 2, //Курьерская доставка с оплатой
    // '24': 12, //Курьерская доставка
    // 'CB24KO': 5, //Возврат с оплатой
    // 'CB24': 15, //Возврат
    // 'B24KO': 8, //Возврат курьером с оплатой
    // 'B24': 18, //Возврат курьером
    // 'VPBZ': 4, //Возврат без предварительной заявки
    // 'C2C': 7, //С2С Отправление
    // 'C2CKO': 17, //С2С Отправление с оплатой получателем
    // 'RECEPTION': 21, //Прием от юрлиц
    // 'VOZVRAT': 23, //Возврат
    // 'SZABOR': 24, //СЗабор
    // 'TRANSFER': 25, //Перемещение
    // 'C2C_RECEPTION': 27, //Прием от физлиц
    // 'CARGO': 50, //Транспортное место
};

const orderServiceNames = {
    1: 'Выдача КО',
    11: 'Выдача',
    22: 'Забор',
    // 2: 'Курьерская доставка КО',
    // 12: 'Курьерская доставка',
    // 5: 'Возврат КО',
    // 15: 'Возврат',
    // 8: 'Возврат курьером КО',
    // 18: 'Возврат курьером',
    // 4: 'Возврат без предварительной заявки',
    // 7: 'С2С Отправление',
    // 17: 'С2С Отправление с оплатой получателем',
    // 21: 'Прием от юрлиц',
    // 23: 'Возврат',
    // 24: 'СЗабор',
    // 27: 'Прием от физлиц',
    // 50: 'Транспортное место',
};

export const getOrderServiceName = (id) => orderServiceNames[id];

export const orderServicesPickup = [
    orderService['C24KO'],
    orderService['C24'],
    // orderService['CB24KO'],
    // orderService['CB24'],
];

export const orderServicesCourier = [
    orderService['24KO'],
    orderService['24'],
    // orderService['B24KO'],
    // orderService['B24'],
];

export const orderDeliveryServices = [
    ...orderServicesPickup,
    ...orderServicesCourier,
];

export const orderServicesPaying = [
    orderService['24KO'],
    orderService['C24KO'],
    // orderService['B24KO'],
    // orderService['CB24KO'],
];

export const orderServicesReturn = [
    orderService['CB24'],
    orderService['CB24KO'],
    // orderService['B24'],
    // orderService['B24KO'],
];

export const orderTransferServices = [
    orderService['ZABOR'],
    // orderService['TRANSFER'],
];
