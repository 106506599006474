import { connection } from 'api/connection';
import { handleRequest } from './request';
import { ApiInstance } from './ApiInstance';

class AuthApiInstance extends ApiInstance {
    constructor(url) {
        super(url);
    }

    async getCurrentUser() {
        return await handleRequest(connection.get(`${this.baseUrl}/user`));
    }

    async logout() {
        return await handleRequest(connection.get('/logout'));
    }
}

export const AuthApi = new AuthApiInstance('/auth');
