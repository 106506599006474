import { createEvent, createStore, createEffect } from 'effector';
import { BalanceApi } from 'api/BalanceApi';
import { createCoeffect } from 'utils/effector';
import { $deviceStatus, $isKktShiftOpened, openKktShift, kktCashIn } from 'store/kkt';
import { getUserStock } from './user';

export const $shiftIsOpen = createStore(true)
    .on(getUserStock.done, (_, { result }) => result.shift);

export const updateDayOpenAmount = createEvent();

export const $dayOpenAmount = createStore(0).on(updateDayOpenAmount, (_, amount) => amount);

export const openDay = createCoeffect($dayOpenAmount, (amount) => BalanceApi.openDay(amount));

export const closeDay = createEffect(() => BalanceApi.closeDay());

export const handleOpenShift = createEffect({ handler: async ({amount, shouldOpenKktShift = false, shouldMakeCashIn = false}) => {
    if ($deviceStatus.getState()) {
        if (shouldOpenKktShift && !$isKktShiftOpened.getState()) {
            await openKktShift();
        }
        if (shouldMakeCashIn) {
            await kktCashIn(amount);
        }
    }
    openDay(amount);
}});


