import { connection } from 'api/connection';
import { handleRequest } from './request';

export class ApiInstance {
    constructor(url) {
        this.baseUrl = url;
    }

    async getAll(params = {}) {
        return await handleRequest(connection.get(`${this.baseUrl}`, { params }));
    }

    async get(id, params = {}) {
        return await handleRequest(connection.get(`${this.baseUrl}/${id}`, { params }));
    }
    async create(model) {
        return await handleRequest(connection.post(`${this.baseUrl}`, model));
    }

    async update(model) {
        return await handleRequest(connection.patch(`${this.baseUrl}/${model.id}`, model));
    }

    async delete({ id }) {
        return await handleRequest(connection.delete(`${this.baseUrl}/${id}`));
    }

    async restore({ id }) {
        return await handleRequest(connection.post(`${this.baseUrl}/restore/${id}`));
    }
}
