import { createEvent, createEffect, createStore, restore } from 'effector';
import { ServerSettingsApi } from 'api/kkt/ServerSettingsApi';

export const updateServerParam = createEvent();

export const getServerSettings = createEffect((params) => ServerSettingsApi.getAll(params));

export const $serverSettings = restore(getServerSettings, {})
    .on(updateServerParam, (state, params) => ({ ...state, ...params }));

export const updateServerSettings = createEffect((params) => ServerSettingsApi.put(params));

updateServerSettings.doneData.watch(getServerSettings);

export const $serverSettingsChanged = createStore(false)
    .on(updateServerParam, () => true)
    .reset(updateServerSettings.done);
