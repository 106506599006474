// import { connection } from 'api/connection';
// import { handleRequest } from './request';
import { ApiInstance } from './ApiInstance';

class DocsApiInstance extends ApiInstance {
    constructor(url) {
        super(url);
    }

    // async saveDocument(params) { return await handleRequest(connection.post(`${this.baseUrl}`, params)) };
}

export const DocsApi = new DocsApiInstance(process.env.API_ENDPOINT + '/documents');
