var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-input",
    _vm._b(
      {
        class: {
          "bg-grey-3": _vm.disable,
          "text-black": _vm.disable,
          "q-field--denser": _vm.denser
        },
        attrs: {
          outlined: "",
          dense: _vm.dense,
          denser: _vm.denser,
          "label-slot": !!_vm.label,
          "stack-label": !!_vm.label
        },
        on: {
          input: value => _vm.$emit("input", value),
          change: event => _vm.$emit("change", event.target.value)
        },
        scopedSlots: _vm._u(
          [
            !!_vm.label
              ? {
                  key: "label",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.label) + " "),
                        _vm.required
                          ? _c("b", { staticClass: "text-negative" }, [
                              _vm._v("*")
                            ])
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "hint",
              fn: function() {
                return [_vm._v("\n        " + _vm._s(_vm.hint) + "\n    ")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "q-input",
      { ..._vm.$attrs, ..._vm.$props },
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }