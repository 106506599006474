export const routesSales = [
    {
        name: 'Sales',
        path: '/sales',
        component: () => import('pages/Sales/Index.vue'),
    },
    {
        name: 'ShippingAcceptForm',
        path: '/sales/shippings/:id',
        props: true,
        component: () => import('pages/Sales/ShippingAcceptForm.vue'),
    },
    {
        name: 'Shippings',
        path: '/sales/shippings',
        component: () => import('pages/Sales/Shippings.vue'),
    },
    {
        name: 'SalesReport',
        path: '/sales/report',
        component: () => import('pages/Sales/Report.vue'),
    },
    {
        name: 'ProductsResidues',
        path: '/sales/residues',
        component: () => import('pages/Sales/Residues.vue'),
    },
];
