export const returnReasons = [
    'Неполная комплектация заказа',
    'Нет необходимости в заказе',
    'Повреждение товара или упаковки',
    'Не устраивает качество/брак товара',
    'Долгое ожидание заказа',
    'Возврат по запросу отправителя',
    'Получатель отказался подтвердить личность',
    'Неверный адрес доставки',
    'Другая причина',
];

export const partialReasons = [
    'Неполная комплектация заказа',
    'Нет необходимости в заказе',
    'Повреждение товара или упаковки',
    'Не устраивает качество/брак товара',
    'Долгое ожидание заказа',
    'Возврат по запросу отправителя',
    'Получатель отказался подтвердить личность',
    'Неверный адрес доставки',
    'Другая причина',
];

export const postponeReasons = [
    'Заказ не отгружался со склада',
    'Курьер не уложился во временной интервал',
    'Курьер не дозвонился до Клиента (ID2)',
    'Изменение адреса доставки',
    'Рабочий адрес (офис)',
    'По договоренности с клиентом (ID4)',
];
