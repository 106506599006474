import { connection } from 'api/connection';
import { handleRequest } from './request';
import { ApiInstance } from './ApiInstance';


class ProdumanApiInstance extends ApiInstance {

    async getToken(content) {
        return await handleRequest(connection.post(`${this.baseUrl}/produman`, content));
    }

}

export const ProdumanApi = new ProdumanApiInstance(process.env.API_ENDPOINT);