import { documentTypes } from 'store/documents';

export const routesDocuments = [
    {
        name: 'Documents',
        path: '/documents',
        props: true,
        component: () => import('pages/Documents/Index.vue'),
        children: [
            {
                name: 'DocumentsApp',
                path: documentTypes.APP,
                props: {
                    doctype: documentTypes.APP,
                },
                component: () => import('pages/Documents/DocumentsTable.vue'),
            },
            {
                name: 'DocumentsExamples',
                path: documentTypes.EXAMPLES,
                component: () => import('pages/Documents/DocsExamples.vue'),
            },
        ],
    },
];
