import { createEffect, restore } from 'effector';
import { MessagesApi } from 'api/MessagesApi';
import { getUserStock } from './user';
import { $authUser } from './auth';

const getStringPlurals = (qty) => {
    switch (true) {
        case qty === 1:
            return `${qty} непрочитанное сообщение`;

        case qty >= 2 && qty <= 4:
            return `${qty} непрочитанных сообщения`;

        default:
            return `${qty} непрочитанных сообщений`;
    }
};

export const getMessages = createEffect(() => MessagesApi.getAll());
export const markAsRead = createEffect((message) => MessagesApi.update(message));

export const $messages = restore(getMessages, []);

export const $unreadMessages = $messages.map((messages) => messages.filter(({ read }) => !read));

markAsRead.done.watch(() => getMessages());

$unreadMessages.watch((messages) => {
    if (messages.length > 0 && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
            type: 'SW_NOTIFICATION',
            message: {
                title: 'КСЭ',
                options: {
                    body: `У вас ${getStringPlurals(
                        messages.length
                    )} от КСЭ. Нажмите здесь, чтобы перейти к сообщениям.`,
                    tag: 'new-messages',
                },
            },
            link: '/#/messages',
        });
    }
});

getUserStock.done.watch(({ result }) => {
    Echo.private(`message.user.${$authUser.getState().id}`)
        .listen('.message.created', () => {
            getMessages();
        })
        .listen('.message.updated', () => {
            getMessages();
        });

    Echo.private(`message.stock.${result.id}`)
        .listen('.message.created', () => {
            getMessages();
        })
        .listen('.message.updated', () => {
            getMessages();
        });
});
