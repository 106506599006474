import { createEffect, restore } from 'effector';
import { $userStock } from 'store/user';
import { DocsApi } from 'api/DocsApi';
import { encodeHTMLToBase64 } from 'utils/formatters';

export const ownerTypes = {
    STOCK: 'stock',
    LEGAL: 'legal',
};
export const documentTypes = {
    APP: 'app',
    EXAMPLES: 'docs_examples',
};

export const getDocuments = createEffect((params) => DocsApi.getAll(params));

export const $documents = restore(getDocuments, []);

export const saveDocument = createEffect(({ elem, owner, type, number }) => {
    const doc = document.getElementById(elem).innerHTML;
    const content = encodeHTMLToBase64(doc);
    const stock = $userStock.getState();
    const model = { owner_type: owner, owner_id: stock.id, content, type, number };

    DocsApi.create(model);
});
