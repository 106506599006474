//>>>>>>>> SERVER INFO >>>>>>>>
export {
    $serverInfo,
    getServerInfo,
} from './kkt/server';

//>>>>>>>> SETTINGS >>>>>>>>
export {
    $serverSettings,
    $serverSettingsChanged,
    getServerSettings,
    updateServerParam,
    updateServerSettings,
} from './kkt/settings';

//>>>>>>>> DEVICES >>>>>>>>
export {
    $devices,
    $device,
    $deviceSettingsChanged,
    getDevices,
    getDeviceById,
    createDevice,
    updateDeviceParam,
    updateDeviceSettings,
    deleteDevice,
    KKT_DEFAULTS,
} from './kkt/devices';

//>>>>>>>> MANAGE MODES >>>>>>>>>
export {
    setDefault,
    activate,
    deactivate,
} from './kkt/modeManagement';

//>>>>>>>> REQUESTS >>>>>>>>
export {
    handleKktRequest,
    getKktRequest,
    postKktRequest,
    openKktShift,
    closeKktShift,
    kktCashIn,
    printSellReceipt,
    printSellReturnReceipt,
    reportX,
} from './kkt/requests';

//>>>>>>>> OPERATIONS >>>>>>>>
export {
    $deviceStatus,
    $isKktShiftOpened,
    $isKktShiftExpired,
    queryDeviceStatus,
} from './kkt/operations';

//>>>>>>>> CONSTANTS >>>>>>>>
export const deviceModels = [
    { value: 500, label: 'Определять автоматически' },
    { value: 57, label: 'АТОЛ 25Ф' },
    { value: 61, label: 'АТОЛ 30Ф' },
    { value: 62, label: 'АТОЛ 55Ф' },
    { value: 63, label: 'АТОЛ 22Ф (АТОЛ FPrint-22ПТК)' },
    { value: 64, label: 'АТОЛ 52Ф' },
    { value: 67, label: 'АТОЛ 11Ф' },
    { value: 69, label: 'АТОЛ 77Ф' },
    { value: 72, label: 'АТОЛ 90Ф' },
    { value: 75, label: 'АТОЛ 60Ф' },
    { value: 76, label: 'Казначей ФА' },
    { value: 77, label: 'АТОЛ 42ФС' },
    { value: 78, label: 'АТОЛ 15Ф' },
    { value: 80, label: 'АТОЛ 50Ф' },
    { value: 81, label: 'АТОЛ 20Ф' },
    { value: 82, label: 'АТОЛ 91Ф' },
    { value: 84, label: 'АТОЛ 92Ф' },
    { value: 87, label: 'АТОЛ 27Ф' },
    { value: 93, label: 'АТОЛ 1Ф' },
];

export const getDeviceModel = (id) => {
    return deviceModels.find(el => el.value == id).label || 'Модель не идентифицирована';
};