<template>
    <q-file
        v-model="file"
        :outlined="outlined"
        :max-file-size="maxFileSize"
        :dense="dense"
        :denser="denser"
        :disable="disable"
        :accept="accept"
        @input="(val) => $emit('input', val)"
        @rejected="onRejected"
    >
        <template #prepend>
            <q-icon name="attach_file" />
        </template>
        <template v-if="!!label" #label>
            <span>{{ label }} <b v-if="required" class="text-negative">*</b></span>
        </template>
        <template #hint>
            {{ hint }}
        </template>
    </q-file>
</template>

<script>
import { Toast } from 'utils/Toast';

export default {
    props: {
        disable: Boolean,
        required: Boolean,
        label: String,
        hint: String,
        dense: Boolean,
        denser: Boolean,
        maxFileSize: [Number, String],
        outlined: {
            type: Boolean,
            default: false,
        },
        accept: String,
    },

    data() {
        return {
            file: null,
        };
    },

    methods: {
        onRejected(rejectedEntries) {
            for (const { failedPropValidation } of rejectedEntries) {
                switch (failedPropValidation) {
                    case 'accept':
                        Toast.error(`Недопустимый формат файла, разрешены только: ${this.accept}`);
                        break;

                    default:
                        Toast.error('Что-то пошло не так, попробуйте снова');
                        break;
                }
            }

        },
    },
};
</script>
