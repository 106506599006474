var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-input",
    _vm._b(
      {
        ref: "inputUpdate",
        class: {
          "input-disabled": !_vm.edit && !_vm.disabled,
          "base-update-input": true
        },
        attrs: {
          readonly: !_vm.edit,
          loading: _vm.loading,
          type: _vm.type,
          outlined: ""
        },
        on: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.handleUpdate.apply(null, arguments)
          }
        },
        scopedSlots: _vm._u(
          [
            !_vm.loading && !_vm.disabled && !_vm.hasError
              ? {
                  key: "append",
                  fn: function() {
                    return [
                      _c("q-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.edit,
                            expression: "!edit"
                          }
                        ],
                        attrs: {
                          size: _vm.dense ? "sm" : "md",
                          color: "primary",
                          round: "",
                          flat: "",
                          icon: "edit"
                        },
                        on: {
                          click: function($event) {
                            _vm.edit = true
                          }
                        }
                      }),
                      _c("q-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.edit,
                            expression: "edit"
                          }
                        ],
                        attrs: {
                          size: _vm.dense ? "sm" : "md",
                          color: "positive",
                          round: "",
                          flat: "",
                          icon: "check"
                        },
                        on: { click: _vm.handleUpdate }
                      })
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        ),
        model: {
          value: _vm.localValue,
          callback: function($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue"
        }
      },
      "q-input",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }