import { render, staticRenderFns } from "./BaseBtn.vue?vue&type=template&id=53058ec6"
import script from "./BaseBtn.vue?vue&type=script&lang=js"
export * from "./BaseBtn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53058ec6')) {
      api.createRecord('53058ec6', component.options)
    } else {
      api.reload('53058ec6', component.options)
    }
    module.hot.accept("./BaseBtn.vue?vue&type=template&id=53058ec6", function () {
      api.rerender('53058ec6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Buttons/BaseBtn.vue"
export default component.exports