var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticStyle: { float: "left", "font-family": "monospace" } },
      [
        _vm.label
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "5px" } },
              [_vm._v(_vm._s(_vm.label))]
            )
          : _vm._e(),
        _vm.number
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "5px" } },
              [_vm._v(_vm._s(_vm.number))]
            )
          : _vm._e(),
        _c("vue-barcode", {
          attrs: {
            value: _vm.barcode,
            height: _vm.height,
            width: _vm.width,
            "display-value": _vm.displayValue
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }