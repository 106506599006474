import { createEffect, createStore } from 'effector';

export const setValidationErrors = createEffect((errors) => errors);

export const $validationErrors = createStore({})
    .on(setValidationErrors, (_, validation) => {
        const newValidation = {};

        for (const [key, value] of Object.entries(validation)) {
            newValidation[key] = value.join(' || ');
        }
        return newValidation;
    });
