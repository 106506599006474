<template>
    <q-uploader
        flat
        hide-upload-btn
        :accept="accept"
        :multiple="multiple"
        :max-files="maxFiles"
        :max-file-size="maxFileSize"
        @added="val => $emit('input', val)"
    >
        <template #header="scope">
            <div class="row no-wrap items-center q-pa-sm q-gutter-xs">
                <q-btn v-if="scope.queuedFiles.length > 0" icon="delete_forever" round dense flat @click="scope.removeQueuedFiles" >
                    <q-tooltip>Удалить все</q-tooltip>
                </q-btn>

                <div class="col">
                    <div v-if="!!label" class="q-uploader__title">{{ label }}</div>
                    <div class="q-uploader__subtitle">{{ scope.files.length }} файл(ов) / {{ scope.uploadSizeLabel }}</div>
                </div>

                <q-btn v-if="scope.canAddFiles" type="a" icon="add_box" round dense flat @click="scope.pickFiles">
                    <q-uploader-add-trigger />
                    <q-tooltip>Добавить файл(ы)</q-tooltip>
                </q-btn>
            </div>
        </template>
    </q-uploader>
</template>

<script>
export default {
    props: {
        label: String,
        accept: String,
        maxFiles: {
            type: [Number, String],
            default: 1,
        },
        maxFileSize: {
            type: [Number, String],
            default: 1024,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
};
</script>