import { createEvent, createEffect, createStore } from 'effector';
import { DeliveryApi } from 'api/DeliveryApi';
import { orderState } from 'config/';
import { getCachedStore } from 'utils/effector';

export const toggleOrderStateById = createEvent();

export const setOrderCell = createEvent();

/**
 * Receipt orders from courier
 */
export const $ordersToReceipt = createStore([]);

export const getOrdersToReceipt = getCachedStore($ordersToReceipt, () =>
    DeliveryApi.getOrdersToReceipt()
);

export const refreshOrdersToReceiptFromApi = createEffect(() => DeliveryApi.getOrdersToReceipt());

$ordersToReceipt
    .on([getOrdersToReceipt.done, refreshOrdersToReceiptFromApi.done], (_, { result }) => result)
    .on(toggleOrderStateById, (orders, { id, state }) =>
        orders.map((order) => (order.id === id ? { ...order, state: Number(state) } : order))
    )
    .on(setOrderCell, (orders, { orderBarcode, cellId }) =>
        orders.map((order) =>
            order.barcode === orderBarcode ? { ...order, cell_id: cellId, state: orderState.IN_STORE } : order
        )
    );

export const $arrivedOrders = $ordersToReceipt.map((orders) => orders.filter(({ arrived_at }) => !!arrived_at));

