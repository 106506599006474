import { getOrderServiceName } from 'config/index';

export const orderServiceOptions = (keys) =>
    keys.reduce((options, key) => {
        options.push({
            label: getOrderServiceName(key),
            value: key,
        });
        return options;
    }, []);

export const remapPagination = ({ perPage, ...pagination }) => ({
    rowsPerPage: perPage,
    ...pagination,
});

export const redirectToAuth = () => (window.location = process.env.AUTH_URL);

export const stringToNumber = (string) => String(string).trim().replace(',', '.');

export const isEmpty = (obj) => {
    if (obj == null) return true;
    if (obj.constructor === Array || typeof obj === 'string') return obj.length === 0;
    if (Object.getPrototypeOf(obj) === Object.prototype) return Object.keys(obj).length === 0;

    return true;
};

export const roundNumber = (number, scale = 2) => {
    let num = number;
    if (typeof number === 'string') {
        num = stringToNumber(num);
    }
    return +num.toFixed(scale);
};

export const numberToWords = (number, words = null) => {
    const result = [];
    let int = '';
    let names = words;
    if (typeof number === 'number') {
        int = number.toString();
    } else if (typeof number !== 'string') {
        int = '';
    }
    if (
        !(names instanceof Array) ||
        typeof names[0] !== 'string' ||
        typeof names[1] !== 'string' ||
        typeof names[2] !== 'string'
    ) {
        names = null;
    }
    if (int.length && !/[^0-9]/.test(int)) {
        var selectName = function (number, names) {
            return names[
                parseInt(number) % 100 > 4 && parseInt(number) % 100 < 20
                    ? 2
                    : [2, 0, 1, 1, 1, 2][Math.min(parseInt(number) % 10, 5)]
            ];
        };
        var name = null;
        var zero = 'ноль';
        if (int === '0') {
            result.push(zero);
        } else {
            var from0To2 = [zero, 'одна', 'две'];
            var from0To19 = [
                zero,
                'один',
                'два',
                'три',
                'четыре',
                'пять',
                'шесть',
                'семь',
                'восемь',
                'девять',
                'десять',
                'одиннадцать',
                'двенадцать',
                'тринадцать',
                'четырнадцать',
                'пятнадцать',
                'шестнадцать',
                'семнадцать',
                'восемнадцать',
                'девятнадцать',
            ];
            var tens = [
                'десять',
                'двадцать',
                'тридцать',
                'сорок',
                'пятьдесят',
                'шестьдесят',
                'семьдесят',
                'восемьдесят',
                'девяносто',
            ];
            var hundreds = [
                'сто',
                'двести',
                'триста',
                'четыреста',
                'пятьсот',
                'шестьсот',
                'семьсот',
                'восемьсот',
                'девятьсот',
            ];
            var thousands = [
                ['тысяча', 'тысячи', 'тысяч'],
                ['миллион', 'миллиона', 'миллионов'],
                ['миллиард', 'миллиарда', 'миллиардов'],
                ['триллион', 'триллиона', 'триллионов'],
                ['квадриллион', 'квадриллиона', 'квадриллионов'],
                ['квинтиллион', 'квинтиллиона', 'квинтиллионов'],
                ['секстиллион', 'секстиллиона', 'секстиллионов'],
                ['септиллион', 'септиллиона', 'септиллионов'],
                ['октиллион', 'октиллиона', 'октиллионов'],
                ['нониллион', 'нониллиона', 'нониллионов'],
                ['дециллион', 'дециллиона', 'дециллионов'],
            ];
            var unknown = '{неизвестно}';
            var numberParts = int.replace(/(?=(\d{3})+(?!\d))/g, ' ').split(' ');
            var i = numberParts.length - 1;
            for (var j in numberParts) {
                var numberPart = parseInt(numberParts[j]);
                if (numberPart) {
                    var numberPartResult = [];
                    var hundred = Math.floor(numberPart / 100);
                    if (hundred) {
                        numberPartResult.push(hundreds[hundred - 1]);
                        numberPart -= hundred * 100;
                    }
                    if (numberPart > 19) {
                        var ten = Math.floor(numberPart / 10);
                        numberPartResult.push(tens[ten - 1]);
                        numberPart -= ten * 10;
                    }
                    if (numberPart) {
                        numberPartResult.push(
                            i === 1 && [1, 2].indexOf(numberPart) !== -1 ? from0To2[numberPart] : from0To19[numberPart]
                        );
                    }
                    if (thousands[i - 1] !== undefined) {
                        numberPartResult.push(selectName(numberParts[j], thousands[i - 1]));
                    } else if (i !== 0) {
                        numberPartResult.push(unknown);
                    } else if (names) {
                        name = selectName(numberParts[j], names);
                    }
                    result.push(numberPartResult.join(' '));
                }
                i--;
            }
            if (!result.length) {
                result.push(zero);
            }
        }
        if (!name && names) {
            name = selectName(0, names);
        }
        if (name) {
            result.push(name);
        }
    }
    return result.join(' ');
};

export const uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
};

export const browserVersion = (() => {
    const ua = navigator.userAgent;
    let tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {
            name: 'IE',
            version: tem[1] || '',
        };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        if (tem != null) {
            return {
                name: 'Opera',
                version: tem[1],
            };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return M;
})();
