var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-banner",
    {
      class: `base-banner ${_vm.bannerClass}`,
      style: [_vm.paddingStyle],
      attrs: { dense: _vm.dense, rounded: _vm.rounded },
      scopedSlots: _vm._u(
        [
          {
            key: "avatar",
            fn: function() {
              return [_vm._t("avatar")]
            },
            proxy: true
          },
          {
            key: "action",
            fn: function() {
              return [_vm._t("action")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }