<template>
    <div class="row q-col-gutter-sm wrap">
        <div class="col-7 q-gutter-y-md">
            <q-list v-if="gmh_items.length" dense flat bordered separator class="col bg-white">
                <q-item>
                    <q-item-section class="col-1">&nbsp;</q-item-section>
                    <q-item-section class="col"><b>Штрихкод</b></q-item-section>
                    <q-item-section class="col"><b>Номер</b></q-item-section>
                </q-item>
                <q-item v-for="item in gmh_items" :key="`return_${item.id}`">
                    <q-item-section class="col-1">
                        <q-icon name="print" size="sm" class="cursor-pointer" @click="$htmlToPaper(`barcode_${item.barcode}_${item.id}`)"/>
                        <v-barcode :id="`barcode_${item.barcode}_${item.id}`" :barcode="item.barcode" :number="item.number" :label="item.shipper_name" hidden />
                    </q-item-section>
                    <q-item-section class="col">
                        {{ item.barcode }}
                    </q-item-section>
                    <q-item-section class="col">{{ item.number }}</q-item-section>
                </q-item>
            </q-list>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // eslint-disable-next-line vue/prop-name-casing
        'gmh_items': {
            type: Array,
            required: true,
        },
    },
};
</script>
