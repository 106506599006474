import { connection } from 'api/connection';
import { handleRequest } from './request';
import { ApiInstance } from './ApiInstance';

class StocksApiInstance extends ApiInstance {
    constructor(url) {
        super(url);
    }

    async getRegions(params) {
        return await handleRequest(connection.get(`${process.env.API_ENDPOINT}/geography`, { params }));
    }

    async addRegion(model) {
        return await handleRequest(connection.post(`${process.env.API_ENDPOINT}/geography`, model));
    }

    async onUser() {
        return await handleRequest(connection.get(`${this.baseUrl}/user`));
    }

    async onLegal() {
        return await handleRequest(connection.get(`${this.baseUrl}/onLegal`));
    }

    async getServices() {
        return await handleRequest(connection.get(`${this.baseUrl}/services`));
    }

    async getStocksByLocation(params) {
        return await handleRequest(connection.get(`${process.env.API_ENDPOINT}/sd/by_location`, { params }));
    }
}

export const StocksApi = new StocksApiInstance(process.env.API_ENDPOINT + '/stocks');
