var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-drawer",
    {
      staticClass: "app-sidebar",
      attrs: {
        value: _vm.value,
        mini: _vm.minifySidebar,
        side: "left",
        bordered: "",
        "show-if-above": ""
      },
      on: {
        mouseover: function($event) {
          _vm.minifySidebar = !_vm.value ? _vm.value : !_vm.value
        },
        mouseout: function($event) {
          _vm.minifySidebar = _vm.value
        },
        hide: function($event) {
          return _vm.$emit("input", false)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "flex column fit no-wrap" },
        [
          _c("app-sidebar-user"),
          !_vm.isLocalAdmin
            ? _c("app-stock-selector", {
                attrs: { minified: _vm.minifySidebar }
              })
            : _vm._e(),
          _c("q-separator"),
          _c(
            "div",
            { staticClass: "sidebar-menu", staticStyle: { "flex-grow": "1" } },
            [
              !_vm.userLoading
                ? _c(
                    "q-list",
                    [
                      _vm._l(_vm.menu, function(menuItem, menuIndex) {
                        return [
                          menuItem.items &&
                          (!("allow" in menuItem) || menuItem.allow)
                            ? _c(
                                "q-expansion-item",
                                {
                                  key: menuIndex,
                                  attrs: {
                                    "header-class":
                                      "text-primary expand-header",
                                    "active-class": "bg-secondary",
                                    "expand-separator": "",
                                    group: "menuGroup",
                                    icon: menuItem.icon,
                                    label: menuItem.name,
                                    "default-opened": _vm.hasActiveRouteChild(
                                      menuItem.items
                                    )
                                  }
                                },
                                [
                                  _vm._l(menuItem.items, function(
                                    item,
                                    itemIndex
                                  ) {
                                    return [
                                      !("allow" in item) || item.allow
                                        ? _c(
                                            "q-item",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.early",
                                                  modifiers: { early: true }
                                                }
                                              ],
                                              key: itemIndex,
                                              attrs: {
                                                to: { name: item.routeName }
                                              }
                                            },
                                            [
                                              "icon" in item
                                                ? _c(
                                                    "q-item-section",
                                                    { attrs: { side: "" } },
                                                    [
                                                      _c("q-icon", {
                                                        attrs: {
                                                          name: item.icon
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("q-item-section", [
                                                _vm._v(_vm._s(item.name))
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            : !("allow" in menuItem) || menuItem.allow
                            ? _c(
                                "q-item",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.early",
                                      modifiers: { early: true }
                                    }
                                  ],
                                  key: menuIndex,
                                  attrs: {
                                    to: {
                                      name:
                                        menuItem.routeName ||
                                        menuItem.items[0].routeName
                                    }
                                  }
                                },
                                [
                                  "icon" in menuItem
                                    ? _c(
                                        "q-item-section",
                                        { attrs: { side: "" } },
                                        [
                                          _c("q-icon", {
                                            attrs: {
                                              color: "primary",
                                              name: menuItem.icon
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("q-item-section", [
                                    _vm._v(_vm._s(menuItem.name))
                                  ]),
                                  "counter" in menuItem &&
                                  _vm.notifyCounter(menuItem.counter)
                                    ? _c(
                                        "q-item-section",
                                        { attrs: { side: "" } },
                                        [
                                          _c("q-badge", {
                                            attrs: {
                                              label: _vm.notifyCounter(
                                                menuItem.counter
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          ),
          _c("sidebar-contacts")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }