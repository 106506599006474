export const routesDelivery = [
    {
        name: 'DeliveryPickup',
        path: '/delivery/pickup',
        component: () => import('pages/Delivery/Pickup/Index.vue'),
        meta: {
            help: '/doc/vydacha-zakazov-PcFGLzSXPm',
        },
    },
    {
        name: 'C2CIndex',
        path: '/c2c',
        component: () => import('pages/Delivery/C2C/Index.vue'),
        meta: {
            help: '/doc/priyom-otpravlenij-fizlic-s2s-E2PxidF4d1',
        },
    },
    {
        name: 'C2CCreate',
        path: '/c2c/create',
        component: () => import('pages/Delivery/C2C/Create.vue'),
        meta: {
            help: '/doc/priyom-otpravlenij-fizlic-s2s-E2PxidF4d1',
        },
    },
    // {
    //     name: 'DeliveryCourier',
    //     path: '/delivery/courier',
    //     component: () => import('pages/Delivery/Courier/Index.vue'),
    // },
];
