export const dataType = {
    PRODUCT: 0,
    DELIVERY: 3,
    DISCOUNT: 6,
    ITEM: 7,
    CONDITION: 10,
    EXPIRY: 16,
    ORDER: 28,
    SENDER: 32,
    PREPAYMENT: 70,
    PHONEDISCOUNT: 88,
};

export const dataStatus = {
    NOT_ACCEPTED: 0,
    IN_STORE: 10,
    REJECTED: 20,
    DELIVERED: 30,
    RETURNED: 50,
};

const dataStatusNames = {
    0: 'Не принят',
    10: 'На складе',
    20: 'Отказ',
    30: 'Выдан',
    50: 'Возвращен',
};

export const getDataStatusName = (status) => dataStatusNames[status];

export const dataState = {
    IN_STORE: 10, // На складе
    SHIPPED: 20, // Отгружен
    COURIER: 30, // У курьера
    Issued: 40, // Филиал
    RETURN_TO_STORE: 50, // Отдано на склад для возврата
    POSTPONE: 60, // Перенос
    DEFECT: 70, // Брак
};

const dataStateNames = {
    10: 'На складе',
    20: 'Отгружен',
    30: 'У курьера',
    40: 'Филиал',
    50: 'Отдано на склад для возврата',
    60: 'Перенос',
    70: 'Брак',
};

export const getDataStateName = (state) => dataStateNames[state];
