import { createEffect, restore, createEvent, combine, sample } from 'effector';
import { filterOrderProducts, filterOrderConditions, checkIssuedOrder } from 'utils/order';
import { assignNested, findById } from 'utils/array';
import { isEmpty, roundNumber } from 'utils/helpers';
import { Toast } from 'utils/Toast';
import { DeliveryApi } from 'api/DeliveryApi';
import { orderServicesPaying, orderServicesReturn, orderServicesCourier, orderActions, dataType } from 'config';
import { OrdersApi } from 'api/OrdersApi';
import { refreshPickupOrdersFromApi } from './pickup';

let cachedOrder = {};

const mergeState = (state, { data = [], ...order }) => {
    cachedOrder = { ...state };
    const newState = { ...state, ...order };
    data.map((item) => {
        const stateItem = findById(newState.data, item.id);
        for (const [key, value] of Object.entries(item)) {
            stateItem[key] = value;
        }
    });
    return newState;
};

/**
 * Изменить значение заказа
 * @param {any} value - новое значение.
 * @param {String} key - ключ, который необходимо изменить.
 * @param {Boolean} isLocal - изменить локально или на сервере.
 */
export const handleUpdateOrder = (value, key, isLocal) => {
    const params = { id: $deliveryOrder.getState().id };
    assignNested(params, value, key);
    return isLocal ? updateDeliveryOrderLocal(params) : updateDeliveryOrder(params);
};



/**
 * Обновить данные в хранилище, не меняя их на сервере
 * @param {Object} params - параметры на изменения
 */
export const updateDeliveryOrderLocal = createEvent();

/**
 * Обновить данные заказа на сервере
 * @param {Object} params - параметры на изменения, должны иметь как минимум id заказа
 */
export const updateDeliveryOrder = createEffect((model) => OrdersApi.update(model));

/**
 * Получить заказ
 * @param {Number} id - id заказа
 * @param {Object} params
 *
 * @returns {Promise}
 */
export const getDeliveryOrder = createEffect((id) => OrdersApi.get(id));

/**
 * Хранилище заказа
 */
export const $deliveryOrder = restore(getDeliveryOrder, {})
    .on(updateDeliveryOrder, mergeState)
    .on(updateDeliveryOrderLocal, mergeState)
    .on(updateDeliveryOrder.fail, () => cachedOrder)
    .reset(getDeliveryOrder);

export const handleUpdateOrderProduct = createEvent();

sample({
    clock: handleUpdateOrderProduct,
    source: $deliveryOrder,
    fn: ({ id, data }, params) => {
        const { origin_qty } = findById(data, params.id);

        if ('qty' in params && params.qty > origin_qty) {
            params.qty = origin_qty;
        }
        return { id, data: [params] };
    },
    target: updateDeliveryOrderLocal,
});

const filterDeliveryAction = async ({ order, action }) => {
    const newData = {
        order: {
            id: order.id,
            service: order.service,
            data: order.data.filter(({ type }) => [dataType.PRODUCT, dataType.DELIVERY].includes(type)),
        },
        action: action,
    };
    switch (action) {
        case orderActions.PARTIAL_ISSUE_NO_DATA:
        case orderActions.PARTIAL_ISSUE_DATA: {
            newData.order = {
                ...newData.order,
                partial_qty: order.partial_qty,
                partial_sum: order.partial_sum,
                pay_method: order.pay_method,
                reject_comment: order.reject_comment,
            };
            break;
        }
        case orderActions.COMPLETE_REFUSE: {
            newData.order = {
                ...newData.order,
                reject_comment: order.reject_comment,
            };
            break;
        }
    }

    if (orderServicesPaying.includes(order.service)) {
        newData.order = {
            ...newData.order,
            cost: order.cost,
            pay_method: order.pay_method,
        };
    }
    await DeliveryApi.action(newData);
};

export const deliveryAction = createEffect((data) => filterDeliveryAction(data));

sample({
    clock: deliveryAction.done,
    target: refreshPickupOrdersFromApi,
});

export const $isDeliveryObligatory = $deliveryOrder.map((order) => order.data ? order.data.some((item) => item.type == 3 && Boolean(item.service)) : false);

/**
 * Товары и услуги заказа
 */
export const deliveryOrderProducts = $deliveryOrder.map(filterOrderProducts);

/**
 * Условия выдачи заказа
 */
export const deliveryOrderConditions = $deliveryOrder.map(filterOrderConditions);

/**
 * Скидка
 */
export const deliveryOrderDiscount = $deliveryOrder.map((order) => order.discount || 0);

/**
 * Заказ требует оплаты
 */
export const orderIsPayable = $deliveryOrder.map((order) => orderServicesPaying.includes(order.service));

/**
 * Заказ является возвратом
 */
export const orderIsReturn = $deliveryOrder.map((order) => orderServicesReturn.includes(order.service));

/**
 * Заказ является курьерским
 */
export const orderIsCourier = $deliveryOrder.map((order) => orderServicesCourier.includes(order.service));

/**
 * Заказ является оформлен
 */
export const orderIsIssued = $deliveryOrder.map(checkIssuedOrder);

/**
 * Оплата наличными или картой
 */
export const $orderPayMethodCash = $deliveryOrder.map((order) => order.pay_method === 'cash');

/**
 * Имеются ли у заказа подробности
 */
export const orderHasProducts = deliveryOrderProducts.map((products) => !isEmpty(products));

/**
 * Имеются ли у заказа отказные позиции
 */
export const orderHasRefuseProducts = deliveryOrderProducts.map((products) => products.some((product) => product.qty != product.origin_qty));

export const orderHasProductsToDeliver = deliveryOrderProducts.map((products) => products.some(product => product.qty));

/**
 * Запрет на частичную выдачу
 */
export const partialNotAllowed = deliveryOrderConditions.map((conditions) =>
    !!conditions.length && conditions.some((condition) => condition.article == '10000' && condition.service)
);

/**
 * Итоговая стоимость заказа
 */

const priceHandler = (order, products, isPayable, hasProducts, isPaymethodCash, withPrepayment = true) => {
    let amount = 0;

    if (!isPayable) {
        return amount;
    }

    if (hasProducts) {
        amount = roundNumber(
            products.reduce((amount, product) => {
                const price = isPaymethodCash ? product.price : product.price_card;
                return amount + (price - product.discount) * product.qty;
            }, 0)
        );
    } else {
        amount = isPaymethodCash ? (order.amount || 0) : (order.amount_card || 0);
    }

    if (!withPrepayment) {
        amount = (amount >= order.prepayment) ? (amount - order.prepayment) : 0;
    }

    return typeof amount === 'number' ? amount.toFixed(2) : amount;
};

export const $deliveryOrderPrice = combine(
    $deliveryOrder,
    deliveryOrderProducts,
    orderIsPayable,
    orderHasProducts,
    $orderPayMethodCash,
    (order, products, isPayable, hasProducts, isPaymethodCash) => {
        return priceHandler(order, products, isPayable, hasProducts, isPaymethodCash, false);
    }
);

export const $actualAmount = combine(
    $deliveryOrder,
    deliveryOrderProducts,
    orderIsPayable,
    orderHasProducts,
    (order, products, isPayable, hasProducts) => {
        return priceHandler(order, products, isPayable, hasProducts, true);
    }
);

export const $actualAmountCard = combine(
    $deliveryOrder,
    deliveryOrderProducts,
    orderIsPayable,
    orderHasProducts,
    (order, products, isPayable, hasProducts) => {
        return priceHandler(order, products, isPayable, hasProducts, false);
    }
);

/**
 * Watchers
 */
$deliveryOrderPrice.watch((price) => {
    $deliveryOrder.getState().cost = price;
});

updateDeliveryOrder.done.watch(() => {
    Toast.success('Заказ обновлен');
});

deliveryAction.fail.watch(({ error }) => {
    Toast.error(error);
});
