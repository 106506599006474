import { createEffect } from 'effector';
import { ModeManagementApi } from 'api/kkt/ModeManagement';

export const setDefault = createEffect((id) => ModeManagementApi.setDefault(id));

export const activate = createEffect((id) => ModeManagementApi.activate(id));

export const deactivate = createEffect((id) => ModeManagementApi.deactivate(id));

setDefault.done.watch(({ params }) => getDeviceById(params));
activate.done.watch(({ params }) => getDeviceById(params));
deactivate.done.watch(({ params }) => getDeviceById(params));
