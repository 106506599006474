import axios from 'axios';
import { Toast } from 'utils/Toast';
import { redirectToAuth } from 'utils/helpers';
import { $validationErrors, setValidationErrors } from 'store/errors';

const domain = process.env.API_DOMAIN;

export const connection = axios.create({
    withCredentials: true,
    baseURL: domain,
});

connection.interceptors.response.use(
    (response) => {
        const { data } = response;

        $validationErrors.reset();

        if (data.success === false) {
            if (data.errors.validation) {
                setValidationErrors(data.errors.validation);
            } else if (typeof data.errors === 'object' && '0' in data.errors) {
                Toast.error(data.errors[0]);
            } else {
                data.errors.forEach(err => Toast.error(err));
            }
            // throw new Error('Что-то пошло не так...');
        }

        if (data.message) {
            Toast.success(data.message);
        }

        return response;
    },
    ({ response }) => {
        if (response.status === 401) {
            redirectToAuth();
        }
        $validationErrors.reset();

        if (response.data.success === false) {
            if (response.data.errors.validation) {
                setValidationErrors(response.data.errors.validation);
            } else {
                Object.values(response.data.errors).forEach(err => Toast.error(err));
            }
        }

        return Promise.reject(response);
    }
);
