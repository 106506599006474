var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 -100 5700 1800",
        preserveAspectRatio: "xMidYMid meet"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: _vm.fill,
          d:
            "M388.53 0L0 1457h357.16L745.23 0zm1570.54 0H1503.2L917.97 464.54l-.2-.33-288.86 229.77 555.18 763h455.9l-555.28-763.12zm3340.18 311.4c6.8-9.53 3.44-21-5-32.41l-223.93-279H3906.58l-85.87 324.4h1448.46c11.38.01 21.74-1.3 30.07-12.99zm-3174.91 794.53c-12.39-17.87-15.5-40.76-9.45-64l165.82-621.18c6.23-24.29 22.58-49.1 46.41-68.2s50.81-28.19 75.17-28.19h1204.74L3693.6-.04H2453.76c-106.74 0-224.87 40.83-327.74 122.92-103.59 82.06-173.35 189.82-201.92 296.6l-165.45 622.55c-28.54 106.16-16.44 212.47 42.64 293.65 58.58 80.93 153.28 121.33 259.09 121.33h1244.53l86.77-324.72H2181.39c-23.31 0-43.93-8.76-57.05-26.36zm3400.37-539.35H3756.6l-85.69 323.83h1770l228.47-143.7zm-595.67 566H3606.76L3520.91 1457h1016.11l416.59-262.37c12.58-7.59 17.36-26.31 11.57-40.23-4.67-11.25-12.58-21.77-36.14-21.77z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }