<template>
    <q-btn
        v-bind="{...$attrs, ...$props}"
        no-wrap
        :outline="outline ? true : disable"
        unelevated
        @click="$emit('click')"
    >
        <slot />
    </q-btn>
</template>

<script>
export default {
    props: {
        outline: Boolean,
        disable: Boolean,
        to: [Object, String],
    },
};
</script>