import { combine, createEffect, createEvent, createStore, restore, sample } from 'effector';
import { orderTypes, cargoTypes } from 'config';
import { getStocksByLocation } from 'store/stocks';
import { $userStock } from 'store/user';
import { C2CApi } from 'api/C2CApi';
import { dataType } from 'config/index';

export const c2cTypes = {
    C2C: 'c2c',
    P4U: 'p4u',
};

export const maxDimensionsC2C = {
    WIDTH: 120,
    HEIGHT: 120,
    LENGTH: 120,
    WEIGHT: 25,
    SUM: 180,
};

export const maxDimensionsP4U = {
    WIDTH: 100,
    HEIGHT: 100,
    LENGTH: 100,
    WEIGHT: 25,
    SUM: 150,
};

export const boxSizes = {
    S: { width: 31, height: 22, length: 12 },
    M: { width: 31, height: 22, length: 24 },
    L: { width: 42, height: 31, length: 32 },
};

const DEFAULT_C2C = {
    origin: null,
    destination: null,
    address: '',
    location: {},
    type: orderTypes.C2C_PVZ_ADDRESS,
    cargoType: cargoTypes.CARGO,
    c2cType: c2cTypes.C2C,
    tariffId: '',
    urgency: '',
    barcode: '',
    number: '',
    start_point: null,
    final_point: null,
    weight: null,
    width: null,
    height: null,
    length: null,
    volume: 1,
    client_name: '',
    client_phone: '',
    client_email: '',
    client_comment: '',
    sender_name: '',
    sender_phone: '',
    sender_email: '',
    sender_comment: '',
    sender_passport: '',
    amount: 0,
    declared_price: 25,
    principal: {},
    data: [
        {
            type: dataType.DELIVERY,
            name: 'Услуга по экспресс-доставке',
            qty: 1,
            price: 0,
            price_card: 0,
        },
    ],
};

const calcAmount = (amount, insurance) => (parseFloat(amount) + parseFloat(insurance || 0)).toFixed(2);

export const updateParams = createEvent();
export const updateAddressParams = createEvent();
export const updateWithLimits = createEvent();
export const updateC2CType = createEvent();
export const updateBoxSize = createEvent();
export const updateTariff = createEvent();
export const updateTariffByBoxSize = createEvent();

export const getC2COrders = createEffect(() => C2CApi.getAll());
export const $allC2COrders = restore(getC2COrders, []);

export const createC2COrder = createEffect((order) => C2CApi.create(order));

export const updateC2COrderEvent = createEvent();
export const updateC2COrder = createEffect((params) => C2CApi.update(params));
export const resetC2COrder = createEvent();

export const getC2CTariffsEvent = createEvent();
export const resetC2CTariffsEvent = createEvent();
export const getC2CTariffs = createEffect((params) => C2CApi.getTariffs(params));
export const $c2cTariffs = restore(getC2CTariffs, []).reset([
    resetC2COrder,
    resetC2CTariffsEvent,
    updateAddressParams,
    updateC2CType,
    updateParams,
    updateWithLimits,
]);

export const $boxSize = createStore(null)
    .on(updateBoxSize, (_, result) => result)
    .on(updateTariff, (state, { Service }) => {
        const str = 'Короб ';

        if (Service.includes(str)) {
            return Service.substring(Service.indexOf(str) + str.length, Service.lastIndexOf('(') - 1);
        }

        return state;
    })
    .reset(updateC2CType);

export const $isBox = $boxSize.map((size) => Object.keys(boxSizes).includes(size));

export const $insuranceTariff = createStore({})
    .on(
        getC2CTariffs.doneData,
        (_, result) => result.find(({ Service }) => Service.toLowerCase().includes('объявленная')) || {}
    )
    .reset(resetC2COrder, resetC2CTariffsEvent, updateAddressParams, updateC2CType);

export const $c2cOrder = createStore(DEFAULT_C2C)
    .on(updateParams, (state, params) => ({ ...state, ...params }))
    .on(updateC2CType, (state, { c2cType }) => {
        state.tariffId = '';
        state.urgency = '';
        state.amount = state.data[0].price = state.data[0].price_card = 0;

        if (c2cType === c2cTypes.P4U) {
            state.type = orderTypes.C2C_PVZ_PVZ;
            state.cargoType = cargoTypes.CARGO;
        }

        return { ...state, c2cType };
    })
    .on(updateBoxSize, (state, params) => ({ ...state, ...boxSizes[params] }))
    .on(updateTariff, (state, { tariffId, Urgency, Total }) => {
        const dims = $isBox.getState() ? boxSizes[$boxSize.getState()] : {};
        const amount = calcAmount(Total, $insuranceTariff.getState().Total);

        state.data[0].price = state.data[0].price_card = amount;

        return {
            ...state,
            tariffId,
            urgency: Urgency,
            amount,
            ...dims,
        };
    })
    .on(updateTariffByBoxSize, (state, { tariffs, size }) => {
        const service = `Короб ${size}`;
        const { id: tariffId, Urgency: urgency, Total } = tariffs.find(({ Service }) => Service.includes(service));
        const dims = boxSizes[$boxSize.getState()] || {};
        const amount = calcAmount(Total, $insuranceTariff.getState().Total);

        state.data[0].price = state.data[0].price_card = amount;

        return {
            ...state,
            tariffId,
            urgency,
            amount,
            ...dims,
        };
    })
    .on(updateAddressParams, (state, { city_fias: destination, address, location, final_point = null }) => {
        if (!state.origin || !state.start_point) {
            const { id, city_id } = $userStock.getState();
            state.origin = city_id;
            state.start_point = id;
        }

        return {
            ...state,
            destination,
            address,
            location,
            final_point,
            urgency: '',
        };
    })
    .on(updateWithLimits, (state, { val, key, min, max }) => {
        let p = val * 1;
        p = Math.min(Math.max(p, min), max);

        return { ...state, [key]: p };
    })
    .on(createC2COrder.doneData, (state, result) => ({ ...state, ...result, pay_method: 'cash' }))
    .on(updateC2COrder.doneData, (state, result) => ({ ...state, ...result }))
    .on(getC2CTariffs.doneData, (state, result) => {
        const boxSize = $boxSize.getState();

        state.tariffId = '';
        state.urgency = '';
        state.amount = state.data[0].price = state.data[0].price_card = 0;

        result.forEach(({ id, Service, Urgency, Total }) => {
            if (!Service.includes(`Короб ${boxSize}`)) {
                return;
            }

            const amount = calcAmount(Total, $insuranceTariff.getState().Total);

            state.tariffId = id;
            state.urgency = Urgency;
            state.amount = state.data[0].price = state.data[0].price_card = amount;
        });

        return { ...state };
    })
    .reset(resetC2COrder);

export const $actualTariffs = combine($c2cTariffs, $c2cOrder, (c2cTariffs, c2cOrder) =>
    c2cTariffs.filter((el) => {
        const service = el.Service.toLowerCase();
        const includesVP = ['короб'].some((w) => service.includes(w));

        if (service.includes('объявленная')) {
            return false;
        }

        switch (c2cOrder.c2cType) {
            case c2cTypes.C2C:
                return !includesVP;

            case c2cTypes.P4U:
                return includesVP;

            default:
                return true;
        }
    })
);

sample({
    source: $actualTariffs,
    clock: updateBoxSize,
    filter: (tariffs) => tariffs.length > 0,
    fn: (tariffs, size) => ({ tariffs, size }),
    target: updateTariffByBoxSize,
});

sample({
    source: $c2cOrder,
    clock: updateAddressParams,
    filter: ({ type }) => type === orderTypes.C2C_PVZ_PVZ,
    fn: ({ location }) => location,
    target: getStocksByLocation,
});

sample({
    source: $c2cOrder,
    clock: getC2CTariffsEvent,
    fn: ({ origin, destination, type, c2cType, cargoType, weight, volume, declared_price, width, height, length }) => ({
        origin,
        destination,
        type,
        c2cType,
        cargoType,
        weight,
        volume,
        declared_price,
        volumeWeight: Number((width * height * length) / 4000).toFixed(2),
    }),
    target: getC2CTariffs,
});

sample({
    source: $c2cOrder,
    clock: updateC2COrderEvent,
    fn: ({ id, amount, pay_method }) => ({ id, cost: amount, pay_method }),
    target: updateC2COrder,
});

export const getPrint = createEffect(({ number, formName }) => C2CApi.getPrint(number, formName));
