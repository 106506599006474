<template>
    <q-select
        :class="{'bg-grey-3': disable, 'text-black': disable}"
        v-bind="{...$attrs, ...$props}"
        outlined
        :label-slot="!!label"
        :stack-label="!!label"
        @filter="filter"
        @input="(value) => $emit('input', value)"
        @change="(value) => $emit('change', value)"
    >
        <template v-if="!!label" #label>
            <span>{{ label }} <b v-if="required" class="text-negative">*</b></span>
        </template>
        <template v-if="!filter" #no-option>
            <q-item>
                <q-item-section class="text-grey">
                    Ничего не найдено
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script>
export default {
    props: {
        disable: Boolean,
        readonly: Boolean,
        required: Boolean,
        label: String,
        value: [String, Number],
        filterFn: Function,
        options: {
            type: Array,
            default: () => ([]),
        },
        multiple: Boolean,
        dense: Boolean,
        hideBottomSpace: Boolean,
    },
    methods: {
        filter(value, update) {
            update(() => {
                this.filterFn ? this.filterFn(value) : value;
            });
        },
    },
};
</script>
