import { $userCanEditStocks, $userIsSuperadmin, $userCanEditUsers } from './permissions';
import { combine, createEvent, createStore } from 'effector';

export const getMenu = createEvent();

export const $menuAdmin = createStore([])
    .on(getMenu, () => [
        {
            name: 'Статистика',
            routeName: 'Index',
            icon: 'dashboard',
        },
        {
            name: 'Сообщения',
            routeName: 'Messages',
            icon: 'mail',
            counter: 'messages',
        },
        {
            name: 'Отчеты',
            icon: 'library_books',
            items: [
                {
                    name: 'Баланс',
                    routeName: 'ReportBalance',
                },
                {
                    name: 'Отчеты агента',
                    routeName: 'ReportAgentList',
                },
                {
                    name: 'Отчет по заказам',
                    routeName: 'ReportDelivery',
                },
                {
                    name: 'Отчет за период',
                    routeName: 'ReportPvz',
                },
                // {
                //     name: 'Отчет по операторам',
                //     routeName: 'ReportOperator',
                // },
                // {
                //     name: 'Отчет по кассе',
                //     routeName: 'ReportStockCash',
                // },
                {
                    name: 'Заказы на ПВЗ',
                    routeName: 'ReportOrdersPvz',
                },
                {
                    name: 'Документы',
                    routeName: 'DocumentsApp',
                },
            ],
        },
        {
            name: 'Организация',
            icon: 'work',
            items: [
                {
                    name: 'Информация',
                    routeName: 'LegalEdit',
                },
                {
                    allow: $userCanEditStocks.getState(),
                    name: 'Пункты',
                    routeName: 'LegalStocks',
                },
                {
                    allow: $userCanEditUsers.getState(),
                    name: 'Пользователи',
                    routeName: 'Users',
                },
                {
                    allow: $userCanEditStocks.getState(),
                    name: 'Нерабочие дни',
                    routeName: 'DayoffCalendar',
                },
                /* {
                    allow: canEditLegal,
                    name: 'Настройка доступа',
                    routeName: 'LegalShippers',
                }, */
            ],
        },
        /* {
            name: 'Доставка',
            icon: 'unarchive',
            items: [
                {
                    name: 'Самовывоз',
                    routeName: 'DeliveryPickup',
                },
            ],
        },
        {
            name: 'Склад',
            icon: 'store',
            items: [
                {
                    name: 'Размещение',
                    routeName: 'StockReception',
                },
                {
                    name: 'Возвраты',
                    routeName: 'Returns2Principals',
                },
                //{
                //    name: 'Инвентаризация',
                //    routeName: 'StockInventory',
                //},
                {
                    name: 'Ячейки',
                    routeName: 'StockCells',
                },
            ],
        }, */
        {
            allow: $userIsSuperadmin.getState(),
            name: 'Администрирование',
            icon: 'build',
            items: [
                {
                    name: 'Роли',
                    routeName: 'Roles',
                },
            ],
        },
    ]);

export const $menuOperator = combine(
    () => [
        {
            name: 'Поиск',
            routeName: 'Index',
            icon: 'search',
        },
        {
            name: 'Сообщения',
            routeName: 'Messages',
            icon: 'mail',
            counter: 'messages',
        },
        {
            name: 'Самовывоз',
            routeName: 'DeliveryPickup',
            icon: 'unarchive',
            counter: 'pickupOrders',
        },
        {
            name: 'Размещение',
            routeName: 'StockReception',
            icon: 'assignment_returned',
            counter: 'stockOrders',
        },
        {
            name: 'Возвраты',
            routeName: 'Returns2Principals',
            icon: 'assignment_return',
            counter: 'unstockOrders',
        },
        {
            name: 'Приём от физлиц (C2C)',
            icon: 'social_distance',
            routeName: 'C2CIndex',
        },
        {
            name: 'Приём от юрлиц (B2C)',
            icon: 'how_to_vote',
            routeName: 'SelfSupply',
        },
        {
            name: 'Ячейки',
            routeName: 'StockCells',
            icon: 'view_quilt',
        },
        {
            name: 'Отчет по заказам',
            routeName: 'ReportDelivery',
            icon: 'insert_chart_outlined',
        },
        {
            name: 'Документы',
            routeName: 'DocumentsApp',
            icon: 'file_copy',
        },
        /* {
            allow: canSell,
            name: 'Продажи',
            icon: 'shop_two',
            items: [
                {
                    name: 'Витрина',
                    routeName: 'Sales',
                },
                {
                    name: 'Поставки',
                    routeName: 'Shippings',
                },
                {
                    name: 'Отчёт',
                    routeName: 'SalesReport',
                },
                {
                    name: 'Остатки',
                    routeName: 'ProductsResidues',
                },
            ],
        }, */
        {
            name: 'Настройки',
            icon: 'settings',
            items: [
                {
                    name: 'ККТ Атол',
                    routeName: 'KktIndex',
                },
                {
                    name: 'Продуман',
                    routeName: 'ProdumanSetting',
                },
            ],
        },
    ]
);
