var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-header",
    { staticClass: "app-header row" },
    [
      _c(
        "q-toolbar",
        { staticClass: "items-center" },
        [
          _c("q-btn", {
            attrs: { flat: "", round: "", dense: "", icon: "menu" },
            on: {
              click: function($event) {
                return _vm.$emit("input", !_vm.value)
              }
            }
          }),
          _c("div", { staticClass: "mobile-hide" }, [
            _vm._v("Интерфейс партнёра")
          ]),
          _c("q-space"),
          _c(
            "q-item-section",
            { attrs: { avatar: "" } },
            [
              _c("q-item-label", [
                _vm._v("ID юрлица: " + _vm._s(_vm.user.legal_id))
              ])
            ],
            1
          ),
          _c("q-btn", {
            attrs: {
              flat: "",
              "no-caps": "",
              href: `https://dpexpress.yonote.ru/share/partner_manual${_vm.helpSection}`,
              type: "a",
              target: "_blank",
              color: "white",
              label: "Инструкция"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }