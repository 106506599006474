import { createEffect, restore } from 'effector';
import { DeliveryApi } from 'api/DeliveryApi';
import { checkIssuedOrder } from 'utils/order';

const isCourierOrder = (order) => order.courier_id;

export const getCourierOrders = createEffect(() => DeliveryApi.getCourierOrders());

/**
 * Курьерские заказы
 */
export const $courierOrders = restore(getCourierOrders, []);

/**
 * Неназначенные заказы
 */
export const $unassignedOrders = $courierOrders.map((orders) =>
    orders.filter((order) => !checkIssuedOrder(order) && !isCourierOrder(order))
);

/**
 * Заказы переданные на курьера
 */
export const $assignedOrders = $courierOrders.map((orders) =>
    orders.filter((order) => isCourierOrder(order) && !checkIssuedOrder(order))
);

/**
 * Оформленные заказы
 */
export const $issuedOrders = $courierOrders.map((orders) => orders.filter((order) => checkIssuedOrder(order)));
