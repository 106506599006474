export const payMethod = {
    cash: 'Наличные',
    cashless: 'Карта',
};

export const pickupMethod = {
    pickup: 'Выдача',
    return: 'Возврат',
};

export const legalOppOptions = ['ИП', 'ООО', 'ОАО', 'ЗАО'];

export const weekDays = {
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
};

export const contactTypes = {
    phone: 'Телефон',
    email: 'Email',
    url: 'Адрес сайта',
};

export const contactTypesOptions = () => {
    return Object.keys(contactTypes)
        .map(key => ({value: key, label: contactTypes[key]}));
};

export const taxTypes = {
    osn: 'общая',
    usnIncome: 'упрощенная (Доход)',
    usnIncomeOutcome: 'упрощенная (Доход минус Расход)',
    envd: 'единый налог на вмененный доход',
    esn: 'единый сельскохозяйственный налог',
    patent: 'патентная система налогообложения',
};

export const taxTypesOptions = () => {
    return Object.keys(taxTypes)
        .map(key => ({value: key, label: taxTypes[key]}));
};

export const printFormNames = {
    MARK_GMH: 'mark_gmh',
    MARK_GMH_10x9: 'mark_gmh_10x9',
    WAYBILL_2_COPIES: 'waybill_2_copies',
    WAYBILL_2_COPIES_X_2: 'waybill_2_copies_x_2',
};