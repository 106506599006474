var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-btn",
    _vm._b(
      {
        attrs: {
          "no-wrap": "",
          outline: _vm.outline ? true : _vm.disable,
          unelevated: ""
        },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      "q-btn",
      { ..._vm.$attrs, ..._vm.$props },
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }