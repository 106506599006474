import { createEffect, createStore, restore } from 'effector';
import { UserApi } from 'api/UserApi';
import { refreshOrdersToReceiptFromApi, refreshOrdersToReturnFromApi } from './warehouse';
import { refreshPickupOrdersFromApi } from './delivery';

export const setUserStock = createEffect((id) => UserApi.setStock(id));

export const getStatistics = createEffect(() => UserApi.getStatistics());

export const getUserStock = createEffect(() => UserApi.getStock());

export const getUserLegal = createEffect(() => UserApi.getLegal());

export const $userStock = createStore({})
    .on(getUserStock.done, (_, { result }) => result)
    .on(setUserStock.done, (_, { result }) => result);

export const $userLegal = restore(getUserLegal, {});

export const $userLegalAbilities = createStore({})
    .on(getUserLegal.done, (_, { result }) => result.abilities || {});

export const $userStockAbilities = createStore({})
    .on(getUserStock.done, (_, { result }) => result.services || {});

export const $userShippers = $userLegal.map((legal) => legal.shippers || []);

export const $userStats = restore(getStatistics, {});

getUserStock.done.watch(({ result }) => {

    Echo.private(`order.toaccept.${result.id}`)
        .listen('.order.created', () => {
            refreshOrdersToReceiptFromApi();
        })
        .listen('.order.updated', () => {
            refreshOrdersToReceiptFromApi();
        });

    Echo.private(`order.toreturn.${result.id}`)
        .listen('.order.updated', () => {
            refreshOrdersToReturnFromApi();
        });

    Echo.private(`order.togiveout.${result.id}`)
        .listen('.order.updated', () => {
            refreshPickupOrdersFromApi();
        });

    Echo.private(`stock.${result.id}`)
        .listen('.stock.updated', () => {
            getUserStock();
        });
});
