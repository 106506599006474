import { createEffect, createEvent, createStore, restore } from 'effector';
import { CellsApi } from 'api/CellsApi';
import { createCoeffect, getCachedStore } from 'utils/effector';
import { Toast } from 'utils/Toast';

export const updateCell = createEvent();

export const $cells = createStore([]);

export const getCells = getCachedStore($cells, (params) => CellsApi.getAll(params));

export const getCellsFromApi = createEffect((params) => CellsApi.getAll(params));

$cells.on([getCells.done, getCellsFromApi.done], (_, { result }) => result);

export const cellsOptions = $cells.map((cells) => cells.map((cell) => ({ label: cell.number, value: cell.id })));

export const fetchCell = createCoeffect($cells, async (cells, id) => {
    const cached = id ? cells.find((cell) => cell.id == id) : {};
    return cached ? cached : CellsApi.get(id);
});

export const $currentCell = restore(fetchCell, {}).on(updateCell, (store, params) => ({ ...store, ...params }));

export const submitCellForm = createCoeffect($currentCell, (cell) =>
    cell.id ? CellsApi.update(cell) : CellsApi.create(cell)
);

submitCellForm.done.watch(({ result }) => {
    getCellsFromApi();

    Toast.success(`Ячейка ${result.barcode} ${$currentCell.getState().id ? 'обновлена' : 'создана'}`);
});
