import axios from 'axios';

export const handleRequest = async (response) => {
    const result = await response;
    if (result.status >= 300) {
        return Promise.reject(result.data.error.description);
    }

    return result.data;
};

export const connection = axios.create({
    withCredentials: false,
    baseURL: 'http://127.0.0.1:16732/api/v2',
});

export class KktApiInstance {

    constructor(url) {
        this.baseUrl = url;
    }

    async getAll(params = {}) {
        return handleRequest(connection.get(this.baseUrl, { params }));
    }

    async get(id, params = {}) {
        return handleRequest(connection.get(`${this.baseUrl}/${id}`, { params }));
    }

    async post(params = {}) {
        return handleRequest(connection.post(this.baseUrl, params));
    }

    async put({ id, params = {} }) {
        return handleRequest(connection.put(`${this.baseUrl}/${id}`, params));
    }

    async delete(id) {
        return handleRequest(connection.delete(`${this.baseUrl}/${id}`));
    }
}
