<template>
    <q-dialog v-model="displayUpdatePrompt" seamless position="top">
        <q-card>
            <q-card-section class="row items-center no-wrap">
                <div class="text-weight-bold q-ml-md">Доступно важное обновление, перезагрузите страницу!</div>
                <q-btn class="q-ml-xs" flat no-caps text-color="red-10" @click="reloadPage">
                    <b>Обновить</b>
                </q-btn>
                <q-space />
                <q-btn v-close-popup icon="close" flat round dense />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    data() {
        return {
            displayUpdatePrompt: false,
            registration: null,
        };
    },

    created() {
        if ('serviceWorker' in navigator) {
            document.addEventListener('SW_UPDATED', this.showRefreshUI, { once: true });
        }
    },

    methods: {
        showRefreshUI(e) {
            this.displayUpdatePrompt = true;
            this.registration = e.detail;
        },

        reloadPage() {
            this.displayUpdatePrompt = false;
            window.location.reload();
        },
    },
};
</script>
