export const findById = (array, id, key = 'id') => array.find((item) => item[key] === id) || {};

export const assignNested = (obj, value, path) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj[lastKey] = value;
    return obj;
};

export const arrayToObject = (array, keyField = 'id') =>
    array.length === 0
        ? {}
        : array.reduce((obj, item) => {
            obj[item[keyField]] = item;
            return obj;
        }, {});

export const filterObject = (obj, filter = false) =>
    Object.fromEntries(Object.entries(obj).filter(([_, value]) => !filter(value, _)));
