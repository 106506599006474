<template>
    <q-input
        ref="inputUpdate"
        v-model="localValue"
        v-bind="{...$attrs, ...$props}"
        :readonly="!edit"
        :loading="loading"
        :type="type"
        outlined
        :class="{'input-disabled': !edit && !disabled, 'base-update-input': true}"
        @keyup.enter="handleUpdate"
    >
        <template v-if="!loading && !disabled && !hasError" #append>
            <q-btn v-show="!edit" :size="dense? 'sm' : 'md'" color="primary" round flat icon="edit" @click="edit=true" />
            <q-btn
                v-show="edit"
                :size="dense? 'sm' : 'md'"
                color="positive"
                round
                flat
                icon="check"
                @click="handleUpdate"
            />
        </template>
        <slot />
    </q-input>
</template>

<script>
export default {
    props: {
        required: Boolean,
        disabled: Boolean,
        type: {
            type: String,
            default: 'text',
        },
        label: String,
        value: [String, Number],
        dense: Boolean,
        callback: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            localValue: this.value,
            edit: false,
            loading: false,
            hasError: false,
        };
    },
    watch: {
        value(newValue) {
            let val = newValue;
            if (this.type == 'number') {
                val = newValue < 0 ? 0 : newValue*1;
            }
            this.localValue = val;
        },
    },
    mounted () {
        this.$watch(
            () => {
                return this.$refs.inputUpdate.innerError;
            },
            (val) => {
                this.hasError = val;
            }
        );
    },
    methods: {
        async handleUpdate() {
            if (!this.edit) return;

            this.edit = false;
            if (this.localValue !== this.value) {
                this.loading = true;
                try {
                    await this.callback(this.localValue);
                } catch (e) {
                    this.localValue = this.value;
                }
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.base-update-input {
    .q-field__control {
        padding-right: 4px !important;
    }
}

.input-disabled {
    .q-field__inner {
        background: $grey-3;
    }
}
</style>
