<template>
    <q-drawer
        :value="value"
        :mini="minifySidebar"
        side="left"
        class="app-sidebar"
        bordered
        show-if-above
        @mouseover="minifySidebar = !value ? value : !value"
        @mouseout="minifySidebar = value"
        @hide="$emit('input', false)"
    >
        <div class="flex column fit no-wrap">
            <app-sidebar-user />
            <app-stock-selector v-if="!isLocalAdmin" :minified="minifySidebar" />
            <q-separator />
            <div class="sidebar-menu" style="flex-grow: 1;">
                <q-list v-if="!userLoading">
                    <template v-for="(menuItem, menuIndex) in menu">
                        <q-expansion-item
                            v-if="menuItem.items && (!('allow' in menuItem) || menuItem.allow)"
                            :key="menuIndex"
                            header-class="text-primary expand-header"
                            active-class="bg-secondary"
                            expand-separator
                            group="menuGroup"
                            :icon="menuItem.icon"
                            :label="menuItem.name"
                            :default-opened="hasActiveRouteChild(menuItem.items)"
                        >
                            <template v-for="(item, itemIndex) in menuItem.items">
                                <q-item
                                    v-if="!('allow' in item) || item.allow"
                                    :key="itemIndex"
                                    v-ripple.early
                                    :to="{ name: item.routeName }"
                                >
                                    <q-item-section v-if="'icon' in item" side>
                                        <q-icon :name="item.icon" />
                                    </q-item-section>
                                    <q-item-section>{{ item.name }}</q-item-section>
                                </q-item>
                            </template>
                        </q-expansion-item>
                        <q-item
                            v-else-if="!('allow' in menuItem) || menuItem.allow"
                            :key="menuIndex"
                            v-ripple.early
                            :to="{ name: menuItem.routeName || menuItem.items[0].routeName }"
                        >
                            <q-item-section v-if="'icon' in menuItem" side>
                                <q-icon color="primary" :name="menuItem.icon" />
                            </q-item-section>
                            <q-item-section>{{ menuItem.name }}</q-item-section>
                            <q-item-section v-if="'counter' in menuItem && notifyCounter(menuItem.counter)" side>
                                <q-badge :label="notifyCounter(menuItem.counter)" />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </div>

            <sidebar-contacts />

        </div>
    </q-drawer>
</template>

<script>
import AppSidebarUser from 'components/app/AppSidebarUser';
import { getCurrentUser } from 'store/auth';
import { $unreadMessages } from 'store/messages';
import { $pickupOrders } from 'store/delivery';
import { $ordersToReceipt, $ordersToReturn } from 'store/warehouse';
import { $menuAdmin, $menuOperator, getMenu } from 'store/menu';
import { $userIsLocalAdmin } from 'store/permissions';

import SidebarContacts from 'components/app/SidebarContacts';

export default {
    components: {
        AppSidebarUser,
        SidebarContacts,
        'app-stock-selector': () => import('components/app/AppStockSelector'),
    },

    effector: {
        menuAdmin: $menuAdmin,
        menuOperator: $menuOperator,
        isLocalAdmin: $userIsLocalAdmin,
        userLoading: getCurrentUser.pending,
        messages: $unreadMessages,
        pickupOrders: $pickupOrders,
        stockOrders: $ordersToReceipt,
        unstockOrders: $ordersToReturn,
    },

    props: {
        value: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            minifySidebar: this.minify,
        };
    },

    computed: {
        menu() {
            return this.isLocalAdmin ? this.menuAdmin : this.menuOperator;
        },
    },

    watch: {
        value(value) {
            this.minifySidebar = value;
            this.$emit('input', value);
        },
    },

    created() {
        getMenu();
    },

    methods: {
        hasActiveRouteChild(items) {
            return items.some((el) => el.routeName === this.$route.name);
        },

        notifyCounter(name) {
            return this[name].length;
        },
    },
};
</script>

<style lang="stylus" scope>
.app-sidebar {
  .q-expansion-item--expanded {
    .expand-header {
      font-weight: bold;
    }
  }
  .q-expansion-item__container {
    a {
      padding-left: 72px;
      border-top: 1px solid $grey-3;
    }
  }
  .q-router-link--exact-active {
    background: $blue-1;
  }
}
</style>
