export const routesCourier = [
    {
        name: 'Couriers',
        path: '/couriers',
        component: () => import('pages/Couriers/Index.vue'),
    },
    {
        name: 'CouriersCreate',
        path: '/couriers/create',
        component: () => import('pages/Couriers/Form.vue'),
    },
    {
        name: 'CouriersEdit',
        path: '/couriers/:id',
        props: true,
        component: () => import('pages/Couriers/Form.vue'),
    },
];
