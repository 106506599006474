import { connection } from 'api/connection';
import { handleRequest } from './request';
import { ApiInstance } from './ApiInstance';

class UserApiInstance extends ApiInstance {
    constructor(url) {
        super(url);
    }

    async setStock(id) {
        return await handleRequest(connection.post(`${this.baseUrl}/stock`, { id }));
    }

    async getStock() {
        return await handleRequest(connection.get(`${this.baseUrl}/stock`));
    }

    async getLegal() {
        return await handleRequest(connection.get(`${this.baseUrl}/legal`));
    }

    async getStatistics() {
        return await handleRequest(connection.get(`${this.baseUrl}/statistics`));
    }
}

export const UserApi = new UserApiInstance(process.env.API_ENDPOINT + '/user');
