import { remapPagination, isEmpty } from 'utils/helpers';

const isPaginatedResponse = (response) => !isEmpty(response.pagination);

export const handleRequest = async (response) => {
    const { data } = await response;
    if (data.success === false) {
        return Promise.reject(response.message);
    }
    if (isPaginatedResponse(data)) {
        return { data: data.data, pagination: remapPagination(data.pagination) };
    }

    return data.data;
};

export const handleFileRequest = async (response, filename = null) => {
    const { data, headers } = await response;

    const blob = new Blob([data], { type: headers['content-type'] });

    const a = document.createElement('a');
    a.target = '_blank';
    a.href = URL.createObjectURL(blob);
    a.download = filename || headers['content-disposition'].match(/filename=(.*)/)[1]; //filename of the file
    a.click();

    return true;
};
