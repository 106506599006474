import { ApiInstance } from './ApiInstance';
import { handleRequest } from './request';
import { connection } from './connection';

class BalanceApiInstance extends ApiInstance {
    constructor(url) {
        super(url);
    }

    async openDay(amount) {
        return await handleRequest(connection.post(`${this.baseUrl}/open_day`, { amount }));
    }

    async closeDay() {
        return await handleRequest(connection.post(`${this.baseUrl}/close_day`));
    }
}

export const BalanceApi = new BalanceApiInstance(process.env.API_ENDPOINT + '/balance');
