export const routesOrder = [
    {
        name: 'Order',
        path: '/orders/:id',
        props: true,
        component: () => import('pages/Order/Index.vue'),
        children: [
            {
                name: 'OrderDelivery',
                path: 'delivery',
                component: () => import('pages/Order/Delivery/OrderForm.vue'),
                meta: {
                    help: '/doc/vydacha-zakazov-PcFGLzSXPm',
                },
            },
            {
                name: 'OrderReport',
                path: 'report',
                props: true,
                component: () => import('pages/Order/Report/ReportOrder.vue'),
            },
        ],
    },
];
