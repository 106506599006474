import { connection, handleRequest, KktApiInstance } from './KktApiInstance';

class ServerSettings extends KktApiInstance {
    constructor(url) {
        super(url);
    }

    async put(params = {}) {
        return handleRequest(connection.put(this.baseUrl, params));
    }

}

export const ServerSettingsApi = new ServerSettings('/settings');
