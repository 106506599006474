var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-layout",
    { attrs: { view: "lHh LpR LFf" } },
    [
      _c("app-header", {
        model: {
          value: _vm.left,
          callback: function($$v) {
            _vm.left = $$v
          },
          expression: "left"
        }
      }),
      _c("app-sidebar", {
        model: {
          value: _vm.left,
          callback: function($$v) {
            _vm.left = $$v
          },
          expression: "left"
        }
      }),
      _c(
        "q-page-container",
        [
          _c(
            "q-page",
            { staticClass: "bg-layout row items-stretch" },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("q-ajax-bar", {
        ref: "bar",
        attrs: { position: "top", color: "indigo", size: "2px" }
      }),
      _c("app-notify")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }