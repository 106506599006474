import { KktApiInstance, connection, handleRequest } from './KktApiInstance';

class ModeManagement extends KktApiInstance {

    setDefault(id) {
        return handleRequest(connection.post('/setDefaultDevice', { id }));
    }

    activate(id) {
        return handleRequest(connection.post(`/activateDevice?deviceID=${id}`));
    }

    deactivate(id) {
        return handleRequest(connection.post(`/deactivateDevice?deviceID=${id}`));
    }

}

export const ModeManagementApi = new ModeManagement();
