import { $userLegal, $userStock } from 'store/user';
import { $authUser } from 'store/auth';

export const onlineChatOptions = () => {
    const user = $authUser.getState();
    const legal = $userLegal.getState();
    const stock = $userStock.getState();

    jivo_api.setContactInfo({
        name: stock.id ? `ПВЗ #${stock.id}` : user.login,
        email: stock.email || user.email,
        phone: stock.phone || user.phoneNumber,
    });

    jivo_api.setCustomData([
        {
            'title': 'Пользователь',
            'content': stock.id
                ? 'Оператор'
                : 'Админ',
            'link': `https://adminpvz.cse.ru/users/${user.id}/edit`,
        },
        {
            'title': 'ПВЗ',
            'content': stock.id
                ? `${stock.name}, ${stock.address_full}`
                : '-',
            'link': `https://adminpvz.cse.ru/stocks/${stock.id}/edit`,
        },
        {
            'title': 'Партнёр',
            'content': legal.name,
            'link': `https://adminpvz.cse.ru/legals/${legal.id}/edit`,
        },
    ]);
};
