<template>
    <q-banner
        :dense="dense"
        :rounded="rounded"
        :class="`base-banner ${bannerClass}`"
        :style="[paddingStyle]"
    >
        <template #avatar>
            <slot name="avatar" />
        </template>
        <slot />
        <template #action>
            <slot name="action" />
        </template>
    </q-banner>
</template>

<script>
export default {
    props: {
        dense: {
            type: Boolean,
        },
        rounded: {
            type: Boolean,
        },
        variant: {
            type: String,
            default: 'default',
        },
        padding: {
            type: String,
        },
    },

    computed: {
        bannerClass() {
            switch (this.variant) {
                case 'primary':
                    return 'bg-primary text-blue-1';

                case 'info':
                    return 'bg-cyan-2 text-blue-grey-10';

                case 'success':
                    return 'bg-teal-2 text-grey-10';

                case 'danger':
                    return 'bg-red-2 text-grey-10';

                case 'warning':
                    return 'bg-amber-2 text-grey-8';

                default:
                    return 'bg-grey-3 text-grey-10';
            }
        },

        paddingStyle() {
            const [ y, x ] = typeof this.padding === 'string'
                ? this.padding.split(' ')
                : [];

            if (!x || !y) {
                return {};
            }

            return {
                padding: `${y} ${x}`,
            };
        },
    },
};
</script>

<style lang="scss">
.base-banner {
    &.q-banner--dense {
        padding: .4rem .7rem;

        .q-banner__content {
            line-height: 1.1rem;
        }
    }

    .q-banner__content {
        font-size: .7rem;
    }
}
</style>