import { dataType } from 'config/';
import { orderStage } from 'config/index';

export const filterOrderProducts = (order) =>
    (order.data && order.data.filter((item) => item.type === dataType.PRODUCT || item.type === dataType.DELIVERY)) ||
    [];

export const filterOrderConditions = (order) =>
    (order.data && order.data.filter((item) => item.type === dataType.CONDITION)) || [];

export const checkIssuedOrder = (order) => order.stage >= orderStage.ISSUED;

export const checkClosedOrder = (order) => order.stage >= orderStage.CLOSED;

export const checkAccountedOrder = (order) => order.stage >= orderStage.ACCOUNTED;

export const filterOrders = (orders, query) => {
    const strQuery = String(query.toLowerCase());
    return orders.filter((order) => {
        return (
            String(order.barcode).includes(strQuery)
            || (order.number ? String(order.number).toLowerCase().includes(strQuery) : false)
            || (order.client_name ? String(order.client_name).toLowerCase().includes(strQuery) : false)
            || String(order.client_phone).replace(/[^\d]/g, '').includes(strQuery)
            || order.parcels.some(({ number }) => String(number).includes(strQuery))
        );
    });
};
