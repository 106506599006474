<template>
    <q-card class="q-pb-md full-width">
        <q-card-section :inset="inset && !breadcrumb" class="row items-center q-px-lg" style="height: 66px">
            <q-btn v-if="breadcrumb" flat color="dark" size="md" icon="arrow_back" @click="$router.go(-1)" />
            <slot name="header" />
        </q-card-section>
        <q-separator />

        <q-card-section class="q-py-sm">
            <div v-if="hasAccess" :class="{ 'q-pa-sm': spaced }">
                <slot />
            </div>
            <div v-else>
                <error403 />
            </div>
        </q-card-section>

        <q-page-sticky position="bottom" :offset="[0, 18]">
            <app-day-open v-if="userIsOperator && !shiftIsOpen" />
            <kkt-shift-banners
                v-else-if="kktShiftNeedsToBeOpened || kktShiftIsExpired"
                :is-closed="kktShiftNeedsToBeOpened"
                :is-expired="kktShiftIsExpired"
            />
        </q-page-sticky>

        <q-inner-loading :showing="loading">
            <q-spinner-ios size="md" class="text-accent" />
            <span class="loader-text text-caption">Секундочку...</span>
        </q-inner-loading>

        <q-inner-loading :showing="kktRequestInProgress">
            <q-spinner-ios size="md" class="text-primary" />
            <span class="loader-text text-caption">Обрабатываем запрос ФР...</span>
        </q-inner-loading>

        <warn-reception-needed/>
    </q-card>
</template>

<script>
import { $shiftIsOpen } from 'store/balance';
import { $userIsOperator } from 'store/permissions';
import {
    $deviceStatus,
    $isKktShiftOpened,
    $isKktShiftExpired,
    handleKktRequest,
} from 'store/kkt';


export default {
    components: {
        'app-day-open': () => import('./AppDayOpen'),
        'error403': () => import('pages/Error403'),
        'kkt-shift-banners': () => import('components/ThirdParty/KktShiftBanners'),
        'warn-reception-needed': () => import('components/Modals/OrdersNeedsReceptionWarning'),
    },

    effector: {
        shiftIsOpen: $shiftIsOpen,
        userIsOperator: $userIsOperator,
        deviceStatus: $deviceStatus,
        isKktShiftOpened: $isKktShiftOpened,
        isKktShiftExpired: $isKktShiftExpired,
        kktRequestInProgress: handleKktRequest.pending,
    },

    props: {
        breadcrumb: Boolean,
        loading: Boolean,
        spaced: Boolean,
        inset: Boolean,
        hasAccess: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        kktShiftNeedsToBeOpened() {
            return this.userIsOperator && this.deviceStatus && !this.isKktShiftOpened && !this.isKktShiftExpired;
        },

        kktShiftIsExpired() {
            return this.userIsOperator && this.deviceStatus && this.isKktShiftExpired;
        },
    },
};
</script>

<style scoped>
.index-top {
    z-index: 10;
}
</style>
