<template>
    <q-layout view="lHh LpR LFf">
        <app-header v-model="left" />

        <app-sidebar v-model="left" />

        <q-page-container>
            <q-page class="bg-layout row items-stretch">
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </q-page>
        </q-page-container>

        <q-ajax-bar ref="bar" position="top" color="indigo" size="2px" />
        <app-notify />
    </q-layout>
</template>

<script>
import AppSidebar from 'components/app/AppSidebar';
import AppHeader from 'components/app/AppHeader';
import AppNotify from 'components/app/AppNotify';

export default {
    name: 'main-layout',

    components: {
        AppSidebar,
        AppHeader,
        AppNotify,
    },
    data() {
        return {
            left: false,
        };
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: all 0.3s ease-in-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
