var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-dialog",
    {
      ref: "dialog",
      attrs: { value: _vm.value },
      on: { hide: _vm.onDialogHide }
    },
    [
      _c(
        "q-card",
        { staticClass: "q-dialog-plugin", style: _vm.dialogStyles },
        [
          _c(
            "q-card-section",
            { staticClass: "row items-center" },
            [
              _c("div", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c("q-space"),
              _c("q-btn", {
                directives: [{ name: "close-popup", rawName: "v-close-popup" }],
                attrs: { icon: "close", flat: "", round: "", dense: "" }
              })
            ],
            1
          ),
          _c("q-separator"),
          _c(
            "q-card-section",
            { staticClass: "q-pa-none" },
            [_vm._t("dialog-body")],
            2
          ),
          _c("q-separator"),
          _c(
            "q-card-actions",
            { staticClass: "q-pa-md", attrs: { align: "between" } },
            [
              !_vm.hideCancel
                ? _c("q-btn", {
                    attrs: { color: "grey-7", outline: "", label: "Отмена" },
                    on: { click: _vm.onCancelClick }
                  })
                : _vm._e(),
              _vm._t("dialog-footer", function() {
                return [
                  _c("q-btn", {
                    attrs: {
                      color: "primary",
                      outline: "",
                      label: "Сохранить",
                      loading: _vm.loading
                    },
                    on: { click: _vm.onOKClick }
                  })
                ]
              })
            ],
            2
          ),
          _c(
            "q-inner-loading",
            { attrs: { showing: _vm.pending } },
            [
              _c("q-spinner-gears", {
                attrs: { size: "50px", color: "primary" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }