import { VueEffector } from 'effector-vue/compat';
import { Dialog, colors } from 'quasar';
import Plugin from '@quasar/quasar-ui-qcalendar';
import '@quasar/quasar-ui-qcalendar/dist/index.css';

import VueHtmlToPaper from 'vue-html-to-paper';

import Echo from 'laravel-echo';

import { getUserStock, getUserLegal } from 'store/user';
import { getCurrentUser } from 'store/auth';
import { getLegalStocks, getUserStocks } from 'store/stocks';
import { getMessages } from 'store/messages';

import { getProdumanSetting } from 'store/produman';
import { queryDeviceStatus } from 'store/kkt';
import { $userIsLocalAdmin } from 'store/permissions';

import AppContent from 'components/app/AppContent';
import BarcodeComponent from 'components/BarcodeComponent';
import LoadingWrapper from 'components/LoadingWrapper';
import BaseBanner from 'components/BaseBanner';
import BaseBtn from 'components/Buttons/BaseBtn';
import BaseInput from 'components/Inputs/BaseInput';
import BaseFile from 'components/Inputs/BaseFile';
import BaseUploader from 'components/Inputs/BaseUploader';
import BaseInputUpdate from 'components/Inputs/BaseInputUpdate';
import BaseSelect from 'components/Inputs/BaseSelect';
import DialogModal from 'components/layouts/DialogModal';
import ExpandingList from 'components/tables/ExpandingList';
import ConsolidatedReturnWidget from 'components/Widgets/ConsolidatedReturnWidget';

import { onlineChatOptions } from 'utils/onlineChat';
import { getOrdersToReceipt, getOrdersToReturn } from 'store/warehouse';
import { getPickupOrders } from 'store/delivery';

export default async ({ Vue }) => {
    /**
     * Extend quasar
     */
    colors.setBrand('inactive', '#444');
    colors.setBrand('layout', '#444');

    Vue.use(Plugin);
    /**
     * register effector bindings
     */
    Vue.use(VueEffector);
    Vue.use(VueHtmlToPaper);

    /**
     * Public components
     */

    Vue.component('loading-wrapper', LoadingWrapper);
    Vue.component('base-banner', BaseBanner);
    Vue.component('base-btn', BaseBtn);
    Vue.component('base-input', BaseInput);
    Vue.component('base-file', BaseFile);
    Vue.component('base-uploader', BaseUploader);
    Vue.component('base-select', BaseSelect);
    Vue.component('base-input-update', BaseInputUpdate);
    Vue.component('app-content', AppContent);
    Vue.component('dialog-modal', DialogModal);
    Vue.component('expanding-list', ExpandingList);
    Vue.component('v-barcode', BarcodeComponent);
    Vue.component('ConsolidatedReturnWidget', ConsolidatedReturnWidget);

    /**
     * redeclare standart confirm dialog to quasar one
     */
    window.qConfirm = (callback, message, title = 'Подтвердите действие') => {
        Dialog.create({
            title: title,
            message: message,
            ok: { flat: true },
            cancel: { flat: true },
            persistent: true,
        }).onOk(() => {
            callback();
        });
    };

    /**
     * Extend vue
     */
    Vue.prototype.$log = console.log.bind(console);
    Vue.prototype.$confirm = window.qConfirm;

    /**
     * Authorization
     */
    getCurrentUser.done.watch(({ result }) => {
        getProdumanSetting();
        getMessages();
        getUserLegal();

        if ($userIsLocalAdmin.getState()) {
            getLegalStocks();
        } else {
            queryDeviceStatus();
            getUserStock();
            getUserStocks();
            getPickupOrders();
            getOrdersToReceipt();
            getOrdersToReturn();
        }

        if (process.env.APP_ENV !== 'local') {
            getUserLegal.done.watch(() => onlineChatOptions());
        }

        window.Pusher = require('pusher-js');
        window.Echo = new Echo({
            broadcaster: process.env.BROADCAST_DRIVER,
            key: process.env.PUSHER_APP_KEY,
            httpHost: process.env.BROADCAST_HOST,
            wsHost: process.env.BROADCAST_HOST,
            wssPort: 443,
            forceTLS: true,
            encrypted: true,
            disableStats: true,
            authEndpoint: `//${process.env.BROADCAST_HOST}/broadcasting/auth`,
            auth: {
                headers: {
                    'user-id': result.id,
                },
            },
        });
    });

    getCurrentUser();

};
