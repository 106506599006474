var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "q-py-md q-pl-xl" }, [
    _c("h6", { staticClass: "q-my-md q-pl-md" }, [_vm._v("Задать вопрос:")]),
    _c("div", { staticClass: "q-pl-md" }, [
      _c("p", [
        _c("a", { attrs: { href: `tel:${_vm.phoneMsk}` } }, [
          _vm._v(_vm._s(_vm.phoneMsk))
        ]),
        _c("br"),
        _c("a", { attrs: { href: `tel:${_vm.phoneSpb}` } }, [
          _vm._v(_vm._s(_vm.phoneSpb))
        ])
      ]),
      _c("p", [
        _c("a", { attrs: { href: _vm.mailtoBody } }, [
          _vm._v(_vm._s(_vm.email))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }