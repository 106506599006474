import { Dialog } from 'quasar';

// const applicationServerPublicKey = 'BIvZOWX8iiqIKS2cHRFWTr8fqHGkhgbh4XDGGUV5rcaKMZEe7heaabSyGS8gQF6-sAfNP880dGisDl1eM0rralA';
const notificationPermission = Notification.permission;

export const askNotificationPermission = function() {
    if (notificationPermission !== 'granted') {
        Notification.requestPermission().then((result) => {
            if (result === 'granted') {
                onPermissionGranted();
            } else {
                onPermissionDenied();
            }
        });
    }
};

const onPermissionGranted = () => {
    console.log('Granted');
};

const onPermissionDenied = () => {
    console.log('Denied');
    Dialog.create({
        title: 'Необходимо разрешить уведомления!',
        message: 'Нажмите ОК, чтобы узнать как это сделать и для чего это нужно',
        ok: { flat: true },
        cancel: { flat: true },
        persistent: true,
    })
        .onOk(() => {
            window.open('https://dpexpress.yonote.ru/share/partner_manual/doc/nastrojka-uvedomlenij-JIEp6xrjFS');
        })
        .onCancel(() => {
            onPermissionDenied();
        });
};

const DEFAULT_OPTIONS = {
    body: 'сообщение от КСЭ',
    icon: '/icons/logo-192x192.png',
    renotify: true,
    requireInteraction: true,
};

export const showNotification = (title = 'КСЭ', options) => {
    askNotificationPermission();

    const extOptions = { ...DEFAULT_OPTIONS, ...options };

    if (notificationPermission !== 'denied') {
        new Notification(title, extOptions);
    }
};
