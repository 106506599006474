import { render, staticRenderFns } from "./BaseUploader.vue?vue&type=template&id=77bb0a65"
import script from "./BaseUploader.vue?vue&type=script&lang=js"
export * from "./BaseUploader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QUploader from 'quasar/src/components/uploader/QUploader.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QUploaderAddTrigger from 'quasar/src/components/uploader/QUploaderAddTrigger.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QUploader,QBtn,QTooltip,QUploaderAddTrigger});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77bb0a65')) {
      api.createRecord('77bb0a65', component.options)
    } else {
      api.reload('77bb0a65', component.options)
    }
    module.hot.accept("./BaseUploader.vue?vue&type=template&id=77bb0a65", function () {
      api.rerender('77bb0a65', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Inputs/BaseUploader.vue"
export default component.exports