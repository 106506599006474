import { ApiInstance } from './ApiInstance';
import { handleRequest } from './request';
import { connection } from './connection';

class C2CApiInstance extends ApiInstance {
    constructor(url) {
        super(url);
    }

    async getTariffs(params) {
        return await handleRequest(connection.post(`${this.baseUrl}/tariffs`, params));
    }

    async getPrint(number, formName) {
        return await handleRequest(connection.get(`${this.baseUrl}/docs/${number}/${formName}`));
    }
}

export const C2CApi = new C2CApiInstance(process.env.API_ENDPOINT + '/c2c');
