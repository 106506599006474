<template>
    <div id="q-app">
        <loading-layout v-if="loading"/>
        <main-layout v-else>
            <router-view />
        </main-layout>
    </div>
</template>

<script>
import { getCurrentUser } from 'store/auth';
import MainLayout from 'components/layouts/MainLayout';

export default {
    effector: {
        loading: getCurrentUser.pending,
    },

    name: 'App',

    components: {
        'main-layout': MainLayout,
        'loading-layout': () => import('components/layouts/LoadingLayout'),
    },
};
</script>

<style></style>
