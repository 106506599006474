import { routesDelivery } from './routesDelivery';
import { routesCourier } from './routesCourier';
import { routesAdmin } from './routesAdmin';
import { routesStock } from './routesStock';
import { routesLegal } from './routesLegal';
import { routesOrder } from './routesOrder';
import { routesReport } from './routesReport';
import { routesSales } from './routesSales';
import { routesDocuments } from './routesDocuments';
import { routesKkt } from './routesKkt';
import { routesProduman } from './routesProduman';
import { routesMessages } from './routesMessages';

const routes = [
    ...routesDelivery,
    ...routesCourier,
    ...routesAdmin,
    ...routesStock,
    ...routesLegal,
    ...routesOrder,
    ...routesReport,
    ...routesSales,
    ...routesDocuments,
    ...routesKkt,
    ...routesProduman,
    ...routesMessages,
    {
        name: 'Index',
        path: '/',
        component: () => import('pages/Index.vue'),
    },
];

// Always leave this as last one
routes.push({
    path: '*',
    component: () => import('pages/Error404.vue'),
});

export default routes;
