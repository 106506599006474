<template>
    <q-btn
        v-bind="{...$attrs, ...$props}"
        outline
        icon="delete"
        size="sm"
        color="negative"
        padding="sm"
        @click="$emit('click')"
    />
</template>

<script>
export default {};
</script>