<template>
    <div class="q-py-md q-pl-xl">
        <h6 class="q-my-md q-pl-md">Задать вопрос:</h6>
        <div class="q-pl-md">
            <p>
                <a :href="`tel:${phoneMsk}`">{{ phoneMsk }}</a><br>
                <a :href="`tel:${phoneSpb}`">{{ phoneSpb }}</a>
            </p>
            <p><a :href="mailtoBody">{{ email }}</a></p>
        </div>
    </div>
</template>

<script>
import { $userStock, $userLegal } from 'store/user';
import { $userIsLocalAdmin } from 'store/permissions';
import { $legalStocks } from 'store/stocks';

export default {
    effector: {
        stock: $userStock,
        legal: $userLegal,
        legalStocks: $legalStocks,
        isAdmin: $userIsLocalAdmin,
    },

    data() {
        return {
            phoneMsk: '+7 (495) 989-51-89',
            phoneSpb: '+7 (812) 413-99-19',
            email: 'pvz@cse.ru',
        };
    },

    computed: {
        mailtoBody() {
            const linebreak = '%0D%0A';
            const divider = '----------------------------------';
            const subject = this.isAdmin
                ? `партнёра ${this.legal.full_name}`
                : `ПВЗ ${this.stock.id}`;
            const body = this.isAdmin
                ? this.legalStocks.map(({ id }) => id).join(', ')
                : `${this.stock.id}${linebreak}${this.stock.address_full}`;

            let str = `mailto:${this.email}?subject=Запрос ${subject}`;
            str += `&body=${linebreak}${linebreak}${divider}${linebreak}`;
            str += 'Не удаляйте текст ниже!';
            str += `${linebreak}${divider}${linebreak}ПВЗ ID: ${body}`;
            str += `${linebreak}${divider}`;

            return str;
        },
    },
};
</script>