import { restore, createEffect, createStore, createEvent } from 'effector';
import { ProdumanApi } from 'api/ProdumanApi';
import { Toast } from 'utils/Toast';
import { openURL } from 'quasar';

export const openProdumanApp = (id) =>
    openURL(`${process.env.PRODUMAN_KASSA_DOMAIN}/app/#/orders/${id}/create-from-link`, null, {
        noopener: true,
    });

export const resetProdumanId = createEvent();

export const getProdumanSetting = createEffect(() => JSON.parse(localStorage.produmanSetting));
export const $produmanSetting = restore(getProdumanSetting, {});
export const saveProdumanSetting = createEffect((setting) => (localStorage.produmanSetting = JSON.stringify(setting)));

saveProdumanSetting.done.watch(() => {
    Toast.success('Настройки успешно сохранены');
    getProdumanSetting();
});

/**
 * Используется ли касса Продуман
 */
export const $produmanIsUsed = $produmanSetting.map((produman) => Boolean(produman.used));

export const getProdumanId = createEffect((order) => ProdumanApi.getToken(order));

export const $produmanId = createStore(null)
    .on(getProdumanId.doneData, (_, { produman_id }) => produman_id)
    .reset(resetProdumanId);

getProdumanId.done.watch(({ result }) => openProdumanApp(result.produman_id));
