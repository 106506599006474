var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row q-col-gutter-sm wrap" }, [
    _c(
      "div",
      { staticClass: "col-7 q-gutter-y-md" },
      [
        _vm.gmh_items.length
          ? _c(
              "q-list",
              {
                staticClass: "col bg-white",
                attrs: { dense: "", flat: "", bordered: "", separator: "" }
              },
              [
                _c(
                  "q-item",
                  [
                    _c("q-item-section", { staticClass: "col-1" }, [
                      _vm._v(" ")
                    ]),
                    _c("q-item-section", { staticClass: "col" }, [
                      _c("b", [_vm._v("Штрихкод")])
                    ]),
                    _c("q-item-section", { staticClass: "col" }, [
                      _c("b", [_vm._v("Номер")])
                    ])
                  ],
                  1
                ),
                _vm._l(_vm.gmh_items, function(item) {
                  return _c(
                    "q-item",
                    { key: `return_${item.id}` },
                    [
                      _c(
                        "q-item-section",
                        { staticClass: "col-1" },
                        [
                          _c("q-icon", {
                            staticClass: "cursor-pointer",
                            attrs: { name: "print", size: "sm" },
                            on: {
                              click: function($event) {
                                return _vm.$htmlToPaper(
                                  `barcode_${item.barcode}_${item.id}`
                                )
                              }
                            }
                          }),
                          _c("v-barcode", {
                            attrs: {
                              id: `barcode_${item.barcode}_${item.id}`,
                              barcode: item.barcode,
                              number: item.number,
                              label: item.shipper_name,
                              hidden: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c("q-item-section", { staticClass: "col" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.barcode) +
                            "\n                "
                        )
                      ]),
                      _c("q-item-section", { staticClass: "col" }, [
                        _vm._v(_vm._s(item.number))
                      ])
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }