import { $authUser } from 'store/auth';

export const $userPermissions = $authUser.map((user) => user.permissions || []);

const checkPermission = (permission) => (permissions) => permissions.includes(permission);

const SUPER_ADMIN = 'SuperAdmin';
const LOCAL_ADMIN = 'LocalAdmin';
const ADMIN = 'Admin';
const OPERATOR = 'Operator';
const TRANSFER_TO_PRINCIPAL = 'Transfer to principal';
const RETURN_ISSUED_ORDER = 'Return issued order';
const RETURN_CLOSED_ORDER = 'Return closed order';
const VIEW_ALL_POINTS = 'View all PVZ';
const EDIT_EXPIRY_DATE = 'Edit expiry date';
const EDIT_ORDER_AMOUNT = 'Edit order amount';
const EDIT_CLIENT = 'Edit client';
const EDIT_BALANCE = 'Edit balance';
const EDIT_USERS = 'Edit users';
const EDIT_STOCKS = 'Edit stocks';
const EDIT_LEGAL = 'Edit legal';
const CREATE_CELLS = 'Create cells';
const ACT_ORDERS = 'Act orders';
const ACCEPT_ORDERS = 'Accept orders';
const VIEW_ORDER = 'View order';
const SEND_MESSAGES = 'Send messages';
const CLOSE_DAY = 'Close day';
const SELL_OUT = 'Sell out';

/**
 * Является ли пользователь суперадмином
 */
export const $userIsSuperadmin = $userPermissions.map(checkPermission(SUPER_ADMIN));
/**
 * Является ли пользователь менеджером
 */
export const $userIsAdmin = $userPermissions.map(checkPermission(ADMIN));
/**
 * Является ли пользователь локальным админом
 */
export const $userIsLocalAdmin = $userPermissions.map(checkPermission(LOCAL_ADMIN));

/**
 * Является ли пользователь оператором
 */
export const $userIsOperator = $userPermissions.map(checkPermission(OPERATOR));

/**
 * Может ли пользователь менять сумму заказа
 */
export const $userCanEditOrderAmount = $userPermissions.map(checkPermission(EDIT_ORDER_AMOUNT));

/**
 * Может ли пользователь возвращать заказ принципалу
 */
export const $userCanTransferToPrincipal = $userPermissions.map(checkPermission(TRANSFER_TO_PRINCIPAL));

/**
 * Может ли пользователь возвращать оформленный заказ на выдачу
 */
export const $userCanReturnIssuedOrder = $userPermissions.map(checkPermission(RETURN_ISSUED_ORDER));

/**
 * Может ли пользователь возвращать закрытый заказ на выдачу
 */
export const $userCanReturnClosedOrder = $userPermissions.map(checkPermission(RETURN_CLOSED_ORDER));

/**
 * Имеет ли пользователь доступ ко всем пунктам
 */
export const $userCanViewAllStocks = $userPermissions.map(checkPermission(VIEW_ALL_POINTS));

/**
 * Может ли пользователь редактировать срок хранения заказа
 */
export const $userCanEditExpiryDate = $userPermissions.map(checkPermission(EDIT_EXPIRY_DATE));

/**
 * Может ли пользователь редактировать данные клиента в заказе
 */
export const $userCanEditClient = $userPermissions.map(checkPermission(EDIT_CLIENT));

/**
 * Может ли пользователь редактировать запись финансовых операций
 */
export const $userCanEditBalance = $userPermissions.map(checkPermission(EDIT_BALANCE));

/**
 * Может ли пользователь редактировать пользователй организации
 */
export const $userCanEditUsers = $userPermissions.map(checkPermission(EDIT_USERS));

/**
 * Может ли пользователь редактировать пункты выдачи
 */
export const $userCanEditStocks = $userPermissions.map(checkPermission(EDIT_STOCKS));

/**
 * Может ли пользователь редактировать данные организации
 */
export const $userCanEditLegal = $userPermissions.map(checkPermission(EDIT_LEGAL));

/**
 * Может ли пользователь создавать ячейки на пункте выдачи
 */
export const $userCanCreateCells = $userPermissions.map(checkPermission(CREATE_CELLS));

/**
 * Может ли пользователь оформлять заказы
 */
export const $userCanActOrders = $userPermissions.map(checkPermission(ACT_ORDERS));

/**
 * Может ли пользователь Принимать заказы
 */
export const $userCanAcceptOrders = $userPermissions.map(checkPermission(ACCEPT_ORDERS));

/**
 * Может ли пользователь просматривать заказ
 */
export const $userCanViewOrder = $userPermissions.map(checkPermission(VIEW_ORDER));

/**
 * Может ли пользователь отправлять сообщения для пунктов выдачи
 */
export const $userCanSendMessages = $userPermissions.map(checkPermission(SEND_MESSAGES));

/**
 * Может ли пользователь отркывать день
 */
export const $userCanCloseDay = $userPermissions.map(checkPermission(CLOSE_DAY));

/**
 * Может ли пользователь осуществлять продажи
 */
export const $userCanSell = $userPermissions.map(checkPermission(SELL_OUT));
