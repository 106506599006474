import { createEffect, createEvent, createStore, restore, sample } from 'effector';
import { sampleFrom, createCoeffect } from 'utils/effector';
import { $ordersToReceipt, setOrderCell } from './orders';
import { $consolidatedReturns, getConsolidatedReturns, setConsToReturn } from './consolidatedOrders';
import { $cells } from 'store/cells';
import { $cellBarcode } from './cellBarcode';
import { $orderBarcode } from './orderBarcode';

// Accept orders
export const $currentOrder = createStore(null);
export const submitOrderBarcode = createEvent();
export const selectOrder = createEffect((order) => order);

sample({
    clock: submitOrderBarcode,
    source: $ordersToReceipt,
    fn: (orders, { target }) =>
        orders.find(
            ({ barcode, parcels }) => barcode === target.value || parcels.some(({ number }) => number === target.value)
        ) || Promise.reject(new Error()),
    target: selectOrder,
});

$currentOrder.on(selectOrder.done, (_, { result }) => result);

export const submitCellBarcode = createEvent();

sample({
    clock: submitCellBarcode,
    source: { order: $currentOrder, cells: $cells },
    fn: ({ order, cells }, input) => {
        const cell = cells.find(({ barcode }) => barcode === input) || Promise.reject(new Error());

        return { orderBarcode: order.barcode, cellId: cell.id };
    },
    target: setOrderCell,
});

// Return orders


$cellBarcode.reset(setOrderCell);
$orderBarcode.reset(setOrderCell);

// Consolidation orders

export const $consolidatedOrders = $consolidatedReturns.map((orders) => orders.filter((order) => order.returnable));
export const $consolidateButtonDisabled = $consolidatedOrders.map((orders) => orders.length === 0);

export const submitConsolidatedReturns = createCoeffect($consolidatedOrders, (orders) =>
    DeliveryApi.submitConsolidatedReturns({
        orders: orders.map((item) => item.id),
    })
);

sample({
    clock: submitConsolidatedReturns.done,
    target: getConsolidatedReturns,
});

export const selectConsolidatedReturns = createCoeffect(
    $consolidatedReturns,
    (orders, barcode) => orders.find((order) => order.barcode === barcode) || Promise.reject(new Error())
);

export const submitConsolidatedReturnBarcode = selectConsolidatedReturns.prepend((value) => value);
export const $currentConsolidatedReturn = restore(selectConsolidatedReturns, null);

const consolidatedSelected = sampleFrom({
    source: $currentConsolidatedReturn,
    target: setConsToReturn,
    fn: (order) => ({ orderBarcode: order.barcode }),
});

sample({
    clock: selectConsolidatedReturns.done.map(({ result }) => result),
    target: consolidatedSelected,
});
