import { createEvent, createEffect, restore } from 'effector';
import { DeliveryApi } from 'api/DeliveryApi';
import { orderState } from 'config/';

export const toggleConsStateById = createEvent();
export const setConsToReturn = createEvent();

export const getConsolidatedReturns = createEffect((shipper) => DeliveryApi.getConsolidatedReturns(shipper));

export const $consolidatedReturns = restore(getConsolidatedReturns, [])
    .on(toggleConsStateById, (orders, { id, returnable }) =>
        orders.map((order) => {
            if (order.id === id) {
                const trueState = orderState.COURIER;
                const falseState = orderState.PENDING_PICKUP;

                const state = returnable ? trueState : falseState;

                return { ...order, state: Number(state), returnable };
            }

            return order;
        })
    )
    .on(setConsToReturn, (orders, { orderBarcode }) =>
        orders.map((order) => (order.barcode === orderBarcode ? { ...order, state: orderState.TRANSFERRED_TO_CARRIER } : order))
    );
