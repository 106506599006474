export const routesStock = [
    {
        name: 'StockReception',
        path: '/warehouse/reception',
        component: () => import('pages/Warehouse/Reception/Index.vue'),
    },
    {
        name: 'Returns2Principals',
        path: '/warehouse/returns',
        component: () => import('pages/Warehouse/Returns/Index.vue'),
        props: { tab: 'returns' },
        meta: {
            help: '/doc/vozvrat-zakazov-gruzotpravitelyu-X48i45062m',
        },
    },
    {
        name: 'ConsolidatedReturns',
        path: '/warehouse/consolidated_returns',
        component: () => import('pages/Warehouse/Returns/Index.vue'),
        props: { tab: 'consolidations' },
    },
    {
        name: 'StockCells',
        path: '/stock/cells',
        component: () => import('pages/Cell/Index.vue'),
        meta: {
            help: '/doc/yacheechnoe-hranenie-S2KnNhfnlQ',
        },
    },
    {
        name: 'StockCellsCreate',
        path: '/stock/cells/create',
        component: () => import('pages/Cell/Edit.vue'),
        meta: {
            help: '/doc/yacheechnoe-hranenie-S2KnNhfnlQ#h-dobavlenie-yacheek',
        },
    },
    {
        name: 'StockCellsEdit',
        path: '/stock/cells/edit/:id',
        props: true,
        component: () => import('pages/Cell/Edit.vue'),
        meta: {
            help: '/doc/yacheechnoe-hranenie-S2KnNhfnlQ#h-redaktirovanie-yachejki',
        },
    },
    {
        name: 'SelfSupply',
        path: '/warehouse/selfsupply',
        component: () => import('pages/Warehouse/SelfSupply/Index.vue'),
        meta: {
            help: '/doc/priyom-otpravlenij-yurlic-b2c-gWgMFjbUaM',
        },
    },
];
