import { register } from 'register-service-worker';
import { askNotificationPermission } from 'utils/push-notifications';

// The ready(), registered(), cached(), updatefound() and updated()
// events passes a ServiceWorkerRegistration instance in their arguments.
// ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration

register(process.env.SERVICE_WORKER_FILE, {
    // The registrationOptions object will be passed as the second argument
    // to ServiceWorkerContainer.register()
    // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/register#Parameter

    // registrationOptions: { scope: './' },

    ready(registration) {
        askNotificationPermission();
        registration.update();
        // console.log(registration.clients);
    },

    registered(registration) {
        setInterval(() => {
            registration.update();
        }, 1000 * 60 * 15);
    },

    cached(/* registration */) {
        // console.log('Content has been cached for offline use.')
    },

    updatefound(/* registration */) {
        console.log('New content is downloading.');
    },

    updated(registration) {
        console.log('New content is available; please refresh.');
        document.dispatchEvent(new CustomEvent('SW_UPDATED', { detail: registration }));
    },

    offline() {
        // console.log('No internet connection found. App is running in offline mode.')
    },

    error(/* err */) {
        // console.error('Error during service worker registration:', err)
    },
});
