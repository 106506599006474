import { createEffect, createEvent, createStore, restore } from 'effector';
import { DevicesApi } from 'api/kkt/DevicesApi';

export const KKT_DEFAULTS = {
    id: '',
    name: '',
    connectionSettings: {
        model: 500,
        accessPassword: '',
        userPassword: '',
        port: 'usb',
        com: '',
        baudRate: 1200,
        usbDevice: 'auto',
        ipAddress: '',
        ipPort: 0,
        mac: '',
        ofdChannel: 'auto',
    },
    otherSettings: {
        useGlobalScriptsSettings: true,
        scriptsPath: '',
        useGlobalInvertCashDrawerStatusFlag: true,
        invertCashDrawerStatus: false,
        useGlobalAdditionalHeaderLines: true,
        additionalHeaderLines: '',
        useGlobalAdditionalFooterLines: true,
        additionalFooterLines: '',
    },
};

export const updateDeviceParam = createEvent();

export const getDevices = createEffect((params) => DevicesApi.getAll(params));

export const $devices = restore(getDevices, []);

export const getDeviceById = createEffect((id, params) => DevicesApi.get(id, params));

export const $device = restore(getDeviceById, KKT_DEFAULTS).on(updateDeviceParam, (state, params) => ({
    ...state,
    ...params,
}));

export const createDevice = createEffect((params) => DevicesApi.post(params));
export const updateDeviceSettings = createEffect((data) => DevicesApi.put(data));

export const $deviceSettingsChanged = createStore(false)
    .on(updateDeviceParam, () => true)
    .reset(updateDeviceSettings.done);

export const deleteDevice = createEffect((id) => DevicesApi.delete(id));

deleteDevice.done.watch(getDevices);
