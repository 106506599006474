import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=89b34cc0&scoped=true"
import script from "./AppHeader.vue?vue&type=script&lang=js"
export * from "./AppHeader.vue?vue&type=script&lang=js"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=89b34cc0&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89b34cc0",
  null
  
)


import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QBtn,QSpace,QItemSection,QItemLabel,QInput,QIcon});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('89b34cc0')) {
      api.createRecord('89b34cc0', component.options)
    } else {
      api.reload('89b34cc0', component.options)
    }
    module.hot.accept("./AppHeader.vue?vue&type=template&id=89b34cc0&scoped=true", function () {
      api.rerender('89b34cc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/app/AppHeader.vue"
export default component.exports