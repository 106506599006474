import { createEvent, createStore, createEffect } from 'effector';
import { createCoeffect, getCachedStore } from 'utils/effector';
import { isEmpty } from 'utils/helpers';
import { Toast } from 'utils/Toast';
import { DeliveryApi } from 'api/DeliveryApi';
import { orderService } from 'config';

const ORDER_PICKUP = 'pickup';
const ORDER_RETURN = 'return';

const getService = (amount, pickup_method) => {
    let service = null;
    if (isEmpty(amount) || isEmpty(pickup_method)) {
        return service;
    }

    if (pickup_method === ORDER_PICKUP) {
        service = amount > 0 ? orderService.C24KO : orderService.C24;
    } else if (pickup_method === ORDER_RETURN) {
        service = amount > 0 ? orderService.CB24KO : orderService.CB24;
    }
    return service;
};

export const $pickupOrders = createStore([]);

export const getPickupOrders = getCachedStore($pickupOrders, () => DeliveryApi.getPickupOrders());

export const refreshPickupOrdersFromApi = createEffect(() => DeliveryApi.getPickupOrders());

$pickupOrders.on([getPickupOrders.done, refreshPickupOrdersFromApi.done], (_, { result }) => result);

export const updatePickupOrder = createEvent();

export const clearPickupOrder = createEvent();

export const $pickupOrder = createStore({})
    .on(updatePickupOrder, (state, params) => {
        const newState = { ...state, ...params };
        newState.service = getService(newState.amount, newState.pickup_method);
        return newState;
    })
    .reset(clearPickupOrder);

export const submitPickupOrder = createCoeffect($pickupOrders, async (order) => {
    return DeliveryApi.createIssuedOrder(order);
});

submitPickupOrder.fail.watch(({ error }) => Toast.error(error));

submitPickupOrder.done.watch(() => {
    Toast.success('Заказ успешно оформлен');
    clearPickupOrder();
});
