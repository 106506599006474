import { connection } from 'api/connection';
import { handleRequest } from './request';
import { ApiInstance } from './ApiInstance';

class DeliveryApiInstance extends ApiInstance {
    constructor(url) {
        super(url);
    }

    async getOrdersToReceipt() {
        return await handleRequest(connection.get(`${this.baseUrl}/reception`));
    }

    async getOrdersToReturn() {
        return await handleRequest(connection.get(`${this.baseUrl}/return`));
    }

    async getConsolidatedReturns() {
        return await handleRequest(connection.get(`${this.baseUrl}/consolidated_returns`));
    }

    async submitConsolidatedReturns(orders) {
        return await handleRequest(connection.post(`${this.baseUrl}/action/return_consolidated`, orders));
    }

    async receiptOrders(orders) {
        return await handleRequest(connection.post(`${this.baseUrl}/action/reception`, orders));
    }

    async returnOrders(orders) {
        return await handleRequest(connection.post(`${this.baseUrl}/action/return`, orders));
    }

    async getPickupOrders() {
        return await handleRequest(connection.get(`${this.baseUrl}/pickup`));
    }

    async getCourierOrders() {
        return await handleRequest(connection.get(`${this.baseUrl}/courier`));
    }

    async getReportOrder(id) {
        return await handleRequest(connection.get(`${this.baseUrl}/report/${id}`));
    }

    async action({ order, action }) {
        return await handleRequest(connection.patch(`${this.baseUrl}/action/${order.id}/${action}`, order));
    }

    async createIssuedOrder(order) {
        return await handleRequest(connection.post(`${this.baseUrl}/action/create_issued`, order));
    }
}

export const DeliveryApi = new DeliveryApiInstance(process.env.API_ENDPOINT + '/delivery');
