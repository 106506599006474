import { createEffect, attach } from 'effector';
import { RequestsApi } from 'api/kkt/RequestsApi';
import { $authUser } from 'store/auth';
import { $userLegal, $userStock } from 'store/user';
import { queryDeviceStatus } from './operations';
import { dataType, dataStatus } from 'config/';
import { uuidv4 } from 'utils/helpers';
import { Toast } from 'utils/Toast';

export const getKktRequest = createEffect((id, params) => RequestsApi.get(id, params));

export const postKktRequest = createEffect((params) => RequestsApi.post(params));

const delay = (n) => new Promise((rs) => setTimeout(rs, n));

export const handleKktRequest = createEffect({
    handler: async (params) => {
        let pending = true;
        let res = {};
        await postKktRequest(params);

        while (pending) {
            await delay(3000);
            const { results } = await getKktRequest(params.uuid);
            res = results[0];

            if (res.status === 'error') {
                Toast.error(res.error.description);
                throw new Error(res.error.description);
            }

            if (res.status === 'ready') {
                pending = false;
            }
        }

        Toast.success('Операция ФР успешно завершена');
        return res;
    },
});

export const openKktShift = attach({
    effect: handleKktRequest,
    source: $authUser,
    mapParams: (_, user) => {
        return {
            uuid: uuidv4(),
            request: [
                {
                    type: 'openShift',
                    operator: {
                        name: user.name,
                        vatin: user.inn,
                    },
                },
            ],
        };
    },
});

openKktShift.done.watch(() => queryDeviceStatus());

export const kktCashIn = attach({
    effect: handleKktRequest,
    source: $authUser,
    mapParams: (amount, user) => {
        return {
            uuid: uuidv4(),
            request: [
                {
                    type: 'cashIn',
                    operator: {
                        name: user.name,
                        vatin: user.inn,
                    },
                    cashSum: +amount,
                },
            ],
        };
    },
});

export const closeKktShift = attach({
    effect: handleKktRequest,
    source: $authUser,
    mapParams: (_, user) => {
        return {
            uuid: uuidv4(),
            request: [
                {
                    type: 'closeShift',
                    operator: {
                        name: user.name,
                        vatin: user.inn,
                    },
                },
            ],
        };
    },
});

closeKktShift.done.watch(() => queryDeviceStatus());

export const printSellReceipt = attach({
    effect: handleKktRequest,
    source: {
        user: $authUser,
        legal: $userLegal,
        stock: $userStock,
    },
    mapParams: (order, s) => {

        let items =
            order.data && order.data.length
                ? order.data
                    .filter((el) => [dataType.PRODUCT, dataType.DELIVERY].includes(el.type) && el.qty > 0)
                    .map((el) => ({
                        type: 'position',
                        name: el.name,
                        price: +el.price,
                        quantity: +el.qty,
                        amount: el.price * el.qty,
                        piece: true,
                        infoDiscountAmount: 0,
                        paymentObject: el.type === 0 ? 'commodity' : 'service',
                        tax: {
                            type: 'none',
                        },
                        supplierInfo: {
                            phones: el.vendor
                                ? el.vendor.phone
                                    ? [el.vendor.phone]
                                    : [order.principal.phone]
                                : order.principal.phone
                                    ? [order.principal.phone]
                                    : [],
                            name: el.vendor ? el.vendor.name : order.principal.full_name,
                            vatin: el.vendor ? el.vendor.inn : order.principal.inn,
                        },
                    }))
                : [
                    {
                        type: 'position',
                        name: `Доставка заказа № ${order.number}`,
                        price: +order.cost,
                        quantity: 1,
                        amount: +order.cost,
                        infoDiscountAmount: 0,
                        department: 1,
                        paymentMethod: order.prepayment > 0 ? 'advance' : 'fullPayment',
                        paymentObject: 'service',
                        piece: false,
                        tax: {
                            type: 'none',
                        },
                        supplierInfo: {
                            phones: order.principal.phone ? [order.principal.phone] : [],
                            name: order.principal.full_name,
                            vatin: order.principal.inn,
                        },
                    },
                ];

        if (order.barcode) {
            items.push({
                type: 'userAttribute',
                name: 'Оплата за доставленный плательщику заказ ',
                value: order.barcode,
            });
        }

        const textItem = {
            type: 'text',
            text: '----------------------------------------',
            wrap: 'none',
        };

        items = [].concat(...items.map((el) => [{ ...el }, { ...textItem }]));

        const params = {
            uuid: uuidv4(),
            request: [
                {
                    type: 'sell',
                    taxationType: s.legal.tax_type,
                    ignoreNonFiscalPrintErrors: true,
                    paymentsPlace: s.stock.address,
                    operator: {
                        name: s.user.name,
                        vatin: s.user.inn,
                    },
                    clientInfo: {
                        emailOrPhone: order.client_phone,
                    },
                    items,
                    payments: [
                        {
                            type: order.pay_method === 'cash' ? '0' : '1', // 0 - cash, 1 - electronicaly
                            sum: +order.cost,
                        },
                    ],
                    // total: order.cost*1 - order.prepayment*1,
                },
            ],
        };

        if (order.prepayment) {
            params.request[0].payments.push({
                type: '2', // 2 - prepaid
                sum: +order.prepayment,
            });
        }

        return params;
    },
});

export const printSellReturnReceipt = attach({
    effect: handleKktRequest,
    source: {
        user: $authUser,
        legal: $userLegal,
        stock: $userStock,
    },
    mapParams: (order, s) => {
        return {
            uuid: uuidv4(),
            request: [
                {
                    type: 'sellReturn',
                    taxationType: s.legal.tax_type,
                    ignoreNonFiscalPrintErrors: false,
                    paymentsPlace: s.stock.address,
                    operator: {
                        name: s.user.name,
                        vatin: s.user.inn,
                    },
                    items:
                        order.data && order.data.length
                            ? order.data
                                .filter(
                                    (el) =>
                                        [dataType.PRODUCT, dataType.DELIVERY].includes(el.type) &&
                                          el.status === dataStatus.DELIVERED
                                )
                                .map((el) => ({
                                    type: 'position',
                                    name: el.name,
                                    price: +el.price,
                                    quantity: +el.qty,
                                    amount: el.price * el.qty,
                                    infoDiscountAmount: 0,
                                    paymentObject: el.type === 0 ? 'commodity' : 'service',
                                    tax: {
                                        type: 'none',
                                    },
                                    supplierInfo: {
                                        phones: order.principal.phone ? [order.principal.phone] : [],
                                        name: order.principal.full_name,
                                        vatin: order.principal.inn,
                                    },
                                }))
                            : [
                                {
                                    type: 'position',
                                    name: `Заказ № ${order.number}`,
                                    price: +order.balance.summ,
                                    quantity: 1,
                                    amount: +order.balance.summ,
                                    infoDiscountAmount: 0,
                                    department: 1,
                                    paymentMethod: order.prepayment > 0 ? 'advance' : 'fullPayment',
                                    paymentObject: 'commodity',
                                    tax: {
                                        type: 'none',
                                    },
                                    supplierInfo: {
                                        phones: order.principal.phone ? [order.principal.phone] : [],
                                        name: order.principal.full_name,
                                        vatin: order.principal.inn,
                                    },
                                },
                            ],
                    supplierInfo: {
                        name: order.principal.full_name,
                        vatin: order.principal.inn,
                    },
                    payments: [
                        {
                            type: order.balance.pay_method === 'cash' ? '0' : '1', // 0 - cash, 1 - electronicaly
                            sum: +order.balance.summ,
                        },
                    ],
                    total: +order.balance.summ,
                },
            ],
        };
    },
});

export const reportX = attach({
    effect: handleKktRequest,
    source: $authUser,
    mapParams: (_, user) => {
        return {
            uuid: uuidv4(),
            request: [
                {
                    type: 'reportX',
                    operator: {
                        name: user.name,
                        vatin: user.inn,
                    },
                },
            ],
        };
    },
});
