import { createEffect, createEvent, sample, attach } from 'effector';
import { isEmpty } from './helpers';

export const createCoeffect = (store, fn) => {
    const fx = createEffect();
    store.watch((s) => {
        fx.use((arg) => fn(s, arg));
    });

    return fx;
};

export function sampleFrom({ source, fn, target }) {
    const clock = createEvent();
    sample({ source, fn, target, clock });

    return clock;
}

export function getItemFromStoreById(store, api) {
    const fxFetchItem = createEffect(api);
    const resolveItem = createEffect({
        handler: ({ items, id }) => {
            const item = items.find((entry) => entry.id === id);
            return item ? item : fxFetchItem(id);
        },
    });

    return attach({
        source: store,
        effect: resolveItem,
        mapParams: (id, items) => ({ items, id }),
    });
}

/**
 * Get store from API or return if already exists
 *
 * @param {Array} store
 * @param {Function} api
 * @returns {Promise}
 */
export function getCachedStore(store, api) {
    const fxFetchStore = createEffect(api);
    const resolveStore = createEffect(({ params, collection }) => {
        return isEmpty(collection) ? fxFetchStore(params) : collection;
    });

    return attach({
        source: store,
        effect: resolveStore,
        mapParams: (params, collection) => ({ params, collection }),
    });
}
