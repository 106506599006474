var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-card",
    { staticClass: "q-pb-md full-width" },
    [
      _c(
        "q-card-section",
        {
          staticClass: "row items-center q-px-lg",
          staticStyle: { height: "66px" },
          attrs: { inset: _vm.inset && !_vm.breadcrumb }
        },
        [
          _vm.breadcrumb
            ? _c("q-btn", {
                attrs: {
                  flat: "",
                  color: "dark",
                  size: "md",
                  icon: "arrow_back"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.go(-1)
                  }
                }
              })
            : _vm._e(),
          _vm._t("header")
        ],
        2
      ),
      _c("q-separator"),
      _c("q-card-section", { staticClass: "q-py-sm" }, [
        _vm.hasAccess
          ? _c(
              "div",
              { class: { "q-pa-sm": _vm.spaced } },
              [_vm._t("default")],
              2
            )
          : _c("div", [_c("error403")], 1)
      ]),
      _c(
        "q-page-sticky",
        { attrs: { position: "bottom", offset: [0, 18] } },
        [
          _vm.userIsOperator && !_vm.shiftIsOpen
            ? _c("app-day-open")
            : _vm.kktShiftNeedsToBeOpened || _vm.kktShiftIsExpired
            ? _c("kkt-shift-banners", {
                attrs: {
                  "is-closed": _vm.kktShiftNeedsToBeOpened,
                  "is-expired": _vm.kktShiftIsExpired
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "q-inner-loading",
        { attrs: { showing: _vm.loading } },
        [
          _c("q-spinner-ios", {
            staticClass: "text-accent",
            attrs: { size: "md" }
          }),
          _c("span", { staticClass: "loader-text text-caption" }, [
            _vm._v("Секундочку...")
          ])
        ],
        1
      ),
      _c(
        "q-inner-loading",
        { attrs: { showing: _vm.kktRequestInProgress } },
        [
          _c("q-spinner-ios", {
            staticClass: "text-primary",
            attrs: { size: "md" }
          }),
          _c("span", { staticClass: "loader-text text-caption" }, [
            _vm._v("Обрабатываем запрос ФР...")
          ])
        ],
        1
      ),
      _c("warn-reception-needed")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }