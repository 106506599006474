import { createEffect, createStore, restore } from 'effector';
import { OperationsApi } from 'api/kkt/OperationsApi';

export const queryDeviceStatus = createEffect((params, id) => OperationsApi.queryDeviceStatus(params, id));

export const $deviceStatus = restore(queryDeviceStatus.done.map(() => true), false)
    .reset(queryDeviceStatus);

export const $isKktShiftOpened = createStore(false)
    .on(queryDeviceStatus.done, (_, { result }) => result.deviceStatus.shift === 'opened');

export const $isKktShiftExpired = createStore(false)
    .on(queryDeviceStatus.done, (_, { result }) => result.deviceStatus.shift === 'expired');
