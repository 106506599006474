import { DeliveryApi } from 'api/DeliveryApi';
import { orderState } from 'config/';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { getCachedStore } from 'utils/effector';

/**
 * Return to principal orders
 */
export const toggleOrderStateByOrderAndPlaceId = createEvent();
export const setOrderToReturn = createEvent();
export const submitOrderToReturnBarcode = createEvent();
export const $ordersToReturn = createStore([]);

export const getOrdersToReturn = getCachedStore($ordersToReturn, () => DeliveryApi.getOrdersToReturn());

export const refreshOrdersToReturnFromApi = createEffect(() => DeliveryApi.getOrdersToReturn());

const setOrderParams = (order, places_data) => {
    const trueState = orderState.COURIER;
    const falseState = orderState.FILIAL;

    const orderIsReturnable = places_data.every(({ returnable }) => returnable);
    const state = orderIsReturnable ? trueState : falseState;

    return { ...order, places_data, state: Number(state), returnable: orderIsReturnable };
};

$ordersToReturn
    .on([getOrdersToReturn.done, refreshOrdersToReturnFromApi.done], (_, { result }) => result.map(order => ({
        ...order,
        places_data: order.places_data.length > 0
            ? order.places_data.map((el) => ({ ...el, returnable: false }))
            : [
                {
                    id: order.id,
                    barcode: order.barcode,
                    weight: order.weight,
                    width: order.width,
                    height: order.height,
                    length: order['length'],
                    returnable: false,
                },
            ],
    })))
    .on(toggleOrderStateByOrderAndPlaceId, (orders, { orderId, id, returnable }) =>
        orders.map((order) => {
            if (order.id === orderId) {
                const places_data = order.places_data.map(place => {
                    if (place.id === id) {
                        return { ...place, returnable };
                    }

                    return place;
                });

                return setOrderParams(order, places_data);
            }

            return order;
        })
            .sort((a, b) => (a.returnable === b.returnable) ? 0 : (a.returnable ? -1 : 1))
    )
    .on(submitOrderToReturnBarcode, (orders, input) =>
        orders.map((order) => {
            if (order.places_data.some(({ barcode }) => barcode === input)) {
                const places_data = order.places_data.map(place => {
                    if (place.barcode === input) {
                        return { ...place, returnable: true };
                    }

                    return place;
                });

                return setOrderParams(order, places_data);
            }

            return order;
        })
            .sort((a, b) => (a.returnable === b.returnable) ? 0 : (a.returnable ? -1 : 1))
    );

export const $ordersReturned = $ordersToReturn.map((orders) => orders.filter((order) => !!order.returnable));
export const $returnButtonDisabled = $ordersReturned.map((orders) => orders.length === 0);

const submitOrders = createEffect((orders) => DeliveryApi.returnOrders({ orders }));

export const submitOrdersToReturn = attach({
    effect: submitOrders,
    source: $ordersReturned,
    mapParams: (_, orders) => orders.map(({ id }) => id),
});

sample({
    clock: submitOrdersToReturn.done,
    target: refreshOrdersToReturnFromApi,
});
