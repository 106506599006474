export const routesKkt = [
    /**
     * Пользователи
     */
    {
        name: 'KktIndex',
        path: '/kkt',
        component: () => import('pages/Admin/Kkt/Index.vue'),
        meta: {
            help: '/doc/rabota-s-kkt-atol-espCVM1mQH',
        },
    },
    {
        name: 'KktServerEdit',
        path: '/kkt/server',
        component: () => import('pages/Admin/Kkt/ServerEdit.vue'),
        meta: {
            help: '/doc/rabota-s-kkt-atol-espCVM1mQH',
        },
    },
    {
        name: 'KktCreate',
        path: '/kkt/create',
        component: () => import('pages/Admin/Kkt/KktEdit.vue'),
        meta: {
            help: '/doc/rabota-s-kkt-atol-espCVM1mQH',
        },
    },
    {
        name: 'KktEdit',
        path: '/kkt/edit/:id',
        component: () => import('pages/Admin/Kkt/KktEdit.vue'),
        meta: {
            help: '/doc/rabota-s-kkt-atol-espCVM1mQH',
        },
    },
];
