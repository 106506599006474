export const routesProduman = [
    {
        name: 'ProdumanSetting',
        path: '/produman',
        props: true,
        component: () => import('pages/Produman/Index.vue'),
        meta: {
            help: '/doc/produman-ctoON1mugj',
        },
    },
];
