<template>
    <q-header class="app-header row">
        <q-toolbar class="items-center">
            <q-btn flat round dense icon="menu" @click="$emit('input', !value)" />
            <div class="mobile-hide">Интерфейс партнёра</div>
            <q-space/>
            <q-item-section avatar>
                <q-item-label>ID юрлица: {{ user.legal_id }}</q-item-label>
            </q-item-section>
            <q-btn
                flat
                no-caps
                :href="`https://dpexpress.yonote.ru/share/partner_manual${helpSection}`"
                type="a"
                target="_blank"
                color="white"
                label="Инструкция"
            />
            <!-- <q-input v-model="search" placeholder="Поиск..." dark dense standout class="q-ml-md">
                <template #append>
                    <q-icon v-if="search === ''" name="search" />
                    <q-icon v-else name="clear" class="cursor-pointer" @click="search = ''" />
                </template>
            </q-input>-->
        </q-toolbar>
    </q-header>
</template>

<script>
import { $authUser } from 'store/auth';
export default {
    props: {
        value: Boolean,
    },

    effector: {
        user: $authUser,
    },

    computed: {
        helpSection(){
            return this.$route.meta.help || '';
        },
    },
};
</script>

<style lang="scss" scoped>
    .app-header {
        margin-left: -1px;
        border-left: 1px solid $dark;
    }
</style>
