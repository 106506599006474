<template>
    <q-dialog ref="dialog" :value="value" @hide="onDialogHide">
        <q-card class="q-dialog-plugin" :style="dialogStyles">

            <q-card-section class="row items-center">
                <div class="text-h6">{{ title }}</div>
                <q-space />
                <q-btn v-close-popup icon="close" flat round dense />
            </q-card-section>
            <q-separator/>

            <q-card-section class="q-pa-none">
                <slot name="dialog-body"/>
            </q-card-section>
            <q-separator />

            <q-card-actions align="between" class="q-pa-md">
                <q-btn v-if="!hideCancel" color="grey-7" outline label="Отмена" @click="onCancelClick" />
                <slot name="dialog-footer">
                    <q-btn
                        color="primary"
                        outline
                        label="Сохранить"
                        :loading="loading"
                        @click="onOKClick"
                    />
                </slot>
            </q-card-actions>

            <q-inner-loading :showing="pending">
                <q-spinner-gears size="50px" color="primary" />
            </q-inner-loading>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean,
        title: String,
        pending: Boolean,
        loading: Boolean,
        width: {
            type: [String, Number],
            default: 600,
        },
        hideCancel: Boolean,
    },

    data() {
        return {
            dialogStyles: {
                width: `${this.width}px`,
                maxWidth: '80vw',
            },
        };
    },

    methods: {
    // following method is REQUIRED
    // (don't change its name --> "show")
        show () {
            this.$refs.dialog.show();
        },

        // following method is REQUIRED
        // (don't change its name --> "hide")
        hide () {
            this.$refs.dialog.hide();
        },

        onDialogHide () {
            // required to be emitted
            // when QDialog emits "hide" event
            this.$emit('input', false);
            this.$emit('hide');
        },

        onOKClick () {
            // on OK, it is REQUIRED to
            // emit "ok" event (with optional payload)
            // before hiding the QDialog
            this.$emit('ok');
            // or with payload: this.$emit('ok', { ... })

            // then hiding dialog
            // this.hide();
        },

        onCancelClick () {
            // we just need to hide dialog
            this.hide();
        },
    },
};
</script>
