<template>
    <div>
        <div style="float:left; font-family: monospace;">
            <div v-if="label" style="text-align:center; margin-top:5px;">{{ label }}</div>
            <div v-if="number" style="text-align:center; margin-top:5px;">{{ number }}</div>
            <vue-barcode
                :value="barcode"
                :height="height"
                :width="width"
                :display-value="displayValue"
            />
        </div>
    </div>
</template>

<script>
import VueBarcode from 'vue-barcode';

export default {
    components: {
        VueBarcode,
    },
    props: {
        barcode: {
            type: String,
            required: true,
        },
        number: String,
        label: String,
        height: {
            type: Number,
            defualt: 70,
        },
        width: {
            type: Number,
            defualt: 1,
        },
        displayValue: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
